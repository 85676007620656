@if (showBackgroundPicker && item && contentEdit) {
  <div class="background-color-picker">
    <coin-color-picker [(ngModel)]="item.backgroundColor"></coin-color-picker>
  </div>
}
@if (item && !item?.['options']) {
  <div
    style="display: block; width: 100%; transition: height 300ms ease-in-out"
    [ngStyle]="{
      height: item.height ? item.height + 'px' : '50px',
      border: contentEdit ? '1px solid var(--purple700)' : ''
    }"></div>
}
@if (item?.['options']; as options) {
  <div
    style="display: block; width: 100%; transition: height 300ms ease-in-out"
    [ngStyle]="{
      height: options.height ? options.height + 'px' : '10px',
      border: contentEdit ? '1px solid var(--purple700)' : ''
    }"></div>
}

@if (contentEdit) {
  <mat-slider class="cmsslider" [max]="100" [min]="5" discrete style="width: 200px">
    <input [(ngModel)]="item.height" matSliderThumb />
  </mat-slider>
}
@if (contentEdit) {
  <p style="display: inline-flex">{{ item.height }}</p>
}
