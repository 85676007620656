export enum ReassignmentReason {
  PlanChanger = 'PlanChanger',
  NewEligible = 'NewEligible',
  NoLongerEligible = 'NoLongerEligible',
  Termination = 'Termination',
  CompanyEntryDate = 'CompanyEntryDate',
  ContractStatus = 'ContractStatus',
  TargetIncentiveAmount = 'TargetIncentiveAmount',
  Manual = 'Manual',
  PlanRecalculation = 'PlanRecalculation'
}
