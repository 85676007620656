<div class="item-container" (click)="onClickItem()" [class.is-selected]="isSelected" [matTooltip]="disableNavigation ? ('allocation.list-view.max-level' | translate) : null">
  <div class="tree-container">
    @if (!isRoot) {
      <div class="tree-wrapper">
        @if (!lastParent) {
          <div class="tree-marker-vertical" [class.last]="last && !isMaxLevel"></div>
        }
        @if (!isMaxLevel) {
          <div class="tree-marker-horizontal"></div>
        }
      </div>
    }

    @if (!isRoot && isMaxLevel) {
      <div class="tree-wrapper shift">
        <div class="tree-marker-vertical" [class.last]="last"></div>
        <div class="tree-marker-horizontal"></div>
      </div>
    }

    <div class="content-wrapper">
      <p class="bold" [class.isRoot]="isRoot">{{ head?.headName }}</p>
      <p [class.isRoot]="isRoot">{{ head?.orgCode }}</p>
    </div>
  </div>
  <div class="action-wrapper">
    <mat-icon class="arrow">arrow_forward_ios</mat-icon>
  </div>
</div>
@if (level < 2 && level !== 0) {
  <coin-c-org-tree-overview-items
    [rootEmployee]="head"
    [level]="2"
    [lastParent]="last"
    [selectedEmployeeId]="selectedEmployeeId"
    (itemSelect)="clickTreeItem.emit()"
    [customOpenEmployeeInViewAction]="customOpenEmployeeInViewAction"
    [service]="service"
    [seasonId]="seasonId">
  </coin-c-org-tree-overview-items>
}
