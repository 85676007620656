<div class="container" (contextmenu)="onRightClick($event)">
  <!-- view only -->
  @if (showAngularTextarea) {
    <div class="angular-editor-textarea p" [innerHTML]="item.content?.['text'] || item.content?.['html'] || item.content"></div>
  }
  @if (showAdditionalEditOptions && item) {
    @if (item.type === 'editor') {
      <div class="background-color-picker">
        <coin-v2-checkbox [(ngModel)]="item.isHighlighted" [label]="'Highlighted'"></coin-v2-checkbox>
      </div>
    }
    <div class="background-color-picker">
      <coin-color-picker [(ngModel)]="item.backgroundColor"></coin-color-picker>
    </div>
    <div class="background-color-picker">
      <coin-color-picker [disabled]="item.isHighlighted" [(ngModel)]="item.textColor" [isTextColorPicker]="true"></coin-color-picker>
    </div>
    <coin-a-email-padding-slider [(ngModel)]="item.paddingTop" label="Space-Top"></coin-a-email-padding-slider>
    <coin-a-email-padding-slider [(ngModel)]="item.paddingBottom" label="Space-Bottom"></coin-a-email-padding-slider>
  }
  <!-- ngx-quill editor v1  -->
  <!-- info: switch to v2 if released in order to remove kolkov editor which supports html tables (see below) -->
  @if (showContentEditor) {
    <quill-editor [modules]="quillSimpleTextModules" [formControl]="quillFormControl"></quill-editor>
  }
  @if (showTextEditor) {
    <quill-editor [modules]="quillSimpleTextModules" [formControl]="quillFormControl"></quill-editor>
  }
  @if (showContentTextEditor) {
    <quill-editor [modules]="quillAdvancedModules" [formControl]="quillFormControl"></quill-editor>
  }
  @if (showContentTextHtml) {
    <quill-editor [modules]="quillAdvancedModules" [formControl]="quillFormControl"></quill-editor>
  }

  <!-- kolkov editor with table support -->
  @if (showAngularHtmlEditor) {
    <angular-editor [config]="angularEditorHTMLConfig" [(ngModel)]="item.content['html']"></angular-editor>
  }
  @if (showFaqTableTextEditor) {
    <angular-editor [config]="angularEditorHTMLConfig" [(ngModel)]="item.text"></angular-editor>
  }

  <!-- opens at right click -->
  @if (contextMenuVisible) {
    <app-contextmenu
      #contextMenu
      [x]="contextmenuX"
      [y]="contextmenuY"
      [contextMenuReplacements]="contextMenuReplacements"
      (addItemToContent)="addItemToCursor($event)"
      (close)="contextMenuVisible = false"></app-contextmenu>
  }
</div>
