import { EmptyGuid, RuleSetV2, RuleV2 } from '@coin/shared/util-models';
import { RuleEngineConditionTypeEnum, RuleEngineOperators } from '@coin/shared/util-enums';

export type ActivityStreamLoadConfig = BaseActivityStreamLoadConfig &
  (
    | ActivityStreamEvaluationMemberLoadConfig
    | ActivityStreamSpecialPaymentProposalLoadConfig
    | ActivityStreamIndividualMultiplierLoadConfig
    | ActivityStreamOrgPlanningOrganisationLoadConfig
    | ActivityStreamOrgPlanningPositionLoadConfig
    | ActivityStreamOrgPlanningEmployeeLoadConfig
    | ActivityStreamSeasonLoadConfig
    | ActivityStreamRecordLoadConfig
    | ActivityStreamSuccessionManagementTalentPoolLoadConfig
    | ActivityStreamClauseVersionLoadConfig
    | ActivityStreamTemplateVersionLoadConfig
    | ActivityStreamHeadcountApprovalPositionRequestLoadConfig
    | ActivityStreamMercerMasterDataLoadConfig
    | ActivityStreamStandingPositionEvaluationLoadConfig
    | ActivityStreamOrgManagementRuleSetLoadConfig
    | ActivityStreamOrgManagementOrgLoadConfig
  ); // add new ones here

export class BaseActivityStreamLoadConfig {
  page?: number;
  size?: number;
}

export class ActivityStreamEvaluationMemberLoadConfig extends BaseActivityStreamLoadConfig {
  seasonId: string;
  memberId: string;

  constructor(initial?: Partial<ActivityStreamEvaluationMemberLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamIndividualMultiplierLoadConfig extends BaseActivityStreamLoadConfig {
  seasonId: string;
  allocationMemberId: string;

  constructor(initial?: Partial<ActivityStreamIndividualMultiplierLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamMeritLoadConfig extends BaseActivityStreamLoadConfig {
  seasonId: string;
  allocationMemberId: string;

  constructor(initial?: Partial<ActivityStreamMeritLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamSpecialPaymentProposalLoadConfig extends BaseActivityStreamLoadConfig {
  seasonId: string;
  recordId: string;

  constructor(initial?: Partial<ActivityStreamSpecialPaymentProposalLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamOrgPlanningOrganisationLoadConfig extends BaseActivityStreamLoadConfig {
  organisationId: string;
  organisationChartId: string;

  constructor(initial?: Partial<ActivityStreamOrgPlanningOrganisationLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamOrgPlanningPositionLoadConfig extends BaseActivityStreamLoadConfig {
  organisationChartId: string;
  positionId: string;

  constructor(initial?: Partial<ActivityStreamOrgPlanningPositionLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamOrgPlanningEmployeeLoadConfig extends BaseActivityStreamLoadConfig {
  organisationChartId: string;
  employeeId: string;

  constructor(initial?: Partial<ActivityStreamOrgPlanningEmployeeLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamSeasonLoadConfig extends BaseActivityStreamLoadConfig {
  seasonId: string;
  constructor(initial?: Partial<ActivityStreamSeasonLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamRecordLoadConfig extends BaseActivityStreamLoadConfig {
  recordId: string;
  constructor(initial?: Partial<ActivityStreamRecordLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamSuccessionManagementTalentPoolLoadConfig extends BaseActivityStreamLoadConfig {
  talentPoolId: string;

  constructor(initial?: Partial<ActivityStreamRecordLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamHeadcountApprovalPositionRequestLoadConfig extends BaseActivityStreamLoadConfig {
  positionRequestId: string;
  constructor(initial?: Partial<ActivityStreamHeadcountApprovalPositionRequestLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamClauseVersionLoadConfig extends BaseActivityStreamLoadConfig {
  clauseId: string;
  versionId: string;

  constructor(initial?: Partial<ActivityStreamClauseVersionLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamTemplateVersionLoadConfig extends BaseActivityStreamLoadConfig {
  templateId: string;
  versionId: string;
  constructor(initial?: Partial<ActivityStreamTemplateVersionLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamMercerMasterDataLoadConfig extends BaseActivityStreamLoadConfig {
  employeeId: string;
  constructor(initial?: Partial<ActivityStreamMercerMasterDataLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamStandingPositionEvaluationLoadConfig extends BaseActivityStreamLoadConfig {
  employeeId: string;
  constructor(initial?: Partial<ActivityStreamStandingPositionEvaluationLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamOrgManagementRuleSetLoadConfig extends BaseActivityStreamLoadConfig {
  ruleSet: RuleSetV2;
  constructor(initial?: Partial<ActivityStreamOrgManagementRuleSetLoadConfig>) {
    super();
    Object.assign(this, initial);
  }
}

export class ActivityStreamOrgManagementOrgLoadConfig extends BaseActivityStreamLoadConfig {
  ruleset: RuleSetV2;

  constructor(initial?: Partial<ActivityStreamOrgManagementOrgLoadConfig>) {
    super();
    Object.assign(this, initial);
  }

  /** Convert rules to match OM Mapping */
  static convertRules(rules: RuleV2[]): RuleV2[] {
    return rules.map(rule => {
      const isRuleSet = !!rule.rules?.length;
      if (isRuleSet) return { ...rule, rules: this.convertRules(rule.rules) };

      const propertyName = rule.field;
      const currentValue = rule.value;
      const { id } = rule;
      return {
        id,
        condition: RuleEngineConditionTypeEnum.And,
        rules: [
          {
            operator: RuleEngineOperators.Equal,
            field: 'ChangedProperties',
            listField: 'PropertyName',
            value: [propertyName.replace('Reference.Value', '').replace('Reference.', '')],
            id: EmptyGuid
          },
          {
            operator: RuleEngineOperators.Equal,
            field: 'ChangedProperties',
            listField: 'CurrentValue',
            value: currentValue,
            id: EmptyGuid
          }
        ]
      };
    });
  }
}
