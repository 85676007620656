<div class="header">
  <div class="left">
    @if (actionContainerLeft) {
      <ng-container *ngTemplateOutlet="actionContainerLeft"> </ng-container>
    }
    <div class="title">
      @if (!titleContainer) {
        <h3>{{ title }}</h3>
      }
      @if (titleContainer) {
        <ng-container *ngTemplateOutlet="titleContainer"> </ng-container>
      }
    </div>
    @if (actionContainerMiddle) {
      <ng-container *ngTemplateOutlet="actionContainerMiddle"> </ng-container>
    }
  </div>
  @if (actionContainerRight) {
    <ng-container *ngTemplateOutlet="actionContainerRight"> </ng-container>
  }
</div>
