import { Directive, ElementRef, EventEmitter, Output, OnDestroy, OnInit } from '@angular/core';
import { IntersectionObserverListDirective } from './intersection-observer-list.directive';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[intersectionObserverElement]',
  exportAs: 'intersection',
  standalone: true
})
export class IntersectionObserverElementDirective implements OnDestroy, OnInit {
  public isIntersecting: boolean;
  private elementRef: ElementRef;

  private parent: IntersectionObserverListDirective;

  @Output() visibilityChange = new EventEmitter<boolean>();

  constructor(parent: IntersectionObserverListDirective, elementRef: ElementRef) {
    this.parent = parent;
    this.elementRef = elementRef;
    this.isIntersecting = false;
  }

  public ngOnDestroy(): void {
    this.parent.remove(this.elementRef.nativeElement);
  }

  public ngOnInit(): void {
    this.parent.add(this.elementRef.nativeElement, (isIntersecting: boolean) => {
      this.isIntersecting = isIntersecting;
      this.visibilityChange.emit(isIntersecting);
    });
  }
}
