<div class="header">
  <div class="text-container">
    <h3>{{ 'service-provider.main-headline' | translate }}</h3>
    <p>
      <b>{{ 'service-provider.sub-headline' | translate }}</b>
    </p>
  </div>
</div>
<div class="scroll-wrapper">
  <div class="container">
    @for (sp of serviceProvider; track sp) {
      <h2>{{ 'service-provider.' + sp.title | translate }}</h2>
      <table>
        <tr class="headline">
          @for (header of sp.header; track header; let i = $index) {
            <th [style.width]="columnWidths[i]">
              @if (header.attribute && header.attribute !== '') {
                <p [innerHtml]="'service-provider.' + header.attribute | translate"></p>
              }
            </th>
          }
        </tr>
        @for (data of sp.data; track data) {
          <tr>
            @for (header of sp.header; track header; let i = $index) {
              <td [style.width]="columnWidths[i]">
                @if (header.attribute && (header.attribute === 'country' || header.attribute === 'spForSharePlans' || header.attribute === 'spForBudgetAllocation')) {
                  <p [innerHtml]="'service-provider.' + data[header.attribute] | translate"></p>
                }
                @if (header.attribute && !(header.attribute === 'country' || header.attribute === 'spForSharePlans' || header.attribute === 'spForBudgetAllocation')) {
                  <p [innerHtml]="data[header.attribute]"></p>
                }
              </td>
            }
          </tr>
        }
      </table>
      @for (footnote of sp.footnote; track footnote) {
        <div>
          @if (footnote.attribute === 'financialSp') {
            <p>{{ 'service-provider.financial-service-footnote' | translate }}</p>
          }
          @if (footnote.attribute === 'otherSp') {
            <p>{{ 'service-provider.other-service-provider-footnote' | translate }}</p>
          }
        </div>
      }
    }

    <p>{{ 'service-provider.list-of-other-services' | translate }}</p>
    <h2>{{ 'service-provider.revocation-consent-shareholder-meetings' | translate }}</h2>
    <p>{{ 'service-provider.revocation-consent-shareholder-meetings-text' | translate }}</p>
    <p style="margin: 0px">Siemens Energy AG</p>
    <p style="margin: 0px">c/o ADEUS Aktienregister-Service-GmbH</p>
    <p style="margin: 0px">20621 Hamburg</p>
    <p style="margin: 0px">{{ 'service-provider.email' | translate }}: <a href="mailto:hv-service.siemens-energy@adeus.de"> hv-service.siemens-energy&#64;adeus.de</a></p>
    <p>{{ 'service-provider.revocation-consent-shareholder-meetings-text-2' | translate }}</p>
    <h2>{{ 'service-provider.right-of-objection' | translate }}</h2>
    <p>{{ 'service-provider.right-of-objection-text' | translate }}</p>
    <p style="margin: 0px"><a href="mailto:hv-service.siemens-energy@adeus.de"> hv-service.siemens-energy&#64;adeus.de</a></p>
  </div>
</div>
