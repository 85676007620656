import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ClearDirects, DirectsState, GetDirects, UserState } from '@coin/modules/auth/data-management';
import { CoinUser } from '@coin/shared/util-models';
import { Store } from '@ngxs/store';
import { DirectsOverviewComponent } from '../directs-overview/directs-overview.component';

@Component({
  selector: 'app-directs',
  templateUrl: './directs.component.html',
  styleUrls: ['./directs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DirectsComponent implements OnInit {
  @Input() isSidebarVisible = false;
  @Input() isActive = false;

  @Output() toggle = new EventEmitter<void>();

  user$ = this.store.select<CoinUser>(UserState.user);
  inCompanyManagerDirects = this.store.selectSignal(DirectsState.inCompanyManagerDirects);
  lineManagerDirects = this.store.selectSignal(DirectsState.lineManagerDirects);

  constructor(
    public dialog: MatDialog,
    private store: Store,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(coinUser => {
      if (coinUser?.id) {
        this.store.dispatch(new GetDirects({ userId: coinUser.id }));
      } else {
        this.store.dispatch(new ClearDirects());
      }
    });
  }

  public openDirectsDialog(): void {
    this.dialog.open(DirectsOverviewComponent, {
      disableClose: true,
      width: '80vw',
      minHeight: '75%',
      maxHeight: '80vh',
      maxWidth: '1000px',
      panelClass: 'close-icon-and-transition',
      data: { lineManager: this.lineManagerDirects(), inCompanyManager: this.inCompanyManagerDirects() }
    });
  }
}
