import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { DirectsState } from '@coin/modules/auth/data-management';
import { environment } from '@coin/shared/util-environments';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppsComponent {
  @Output() toggle = new EventEmitter<void>();

  isLineManager$ = this.store.select(DirectsState.isLineManager);

  public environment = environment;

  constructor(private store: Store) {}
}
