@if (templatesByGroup$ | async; as templatesByGroup) {
  @for (group of groups; track group) {
    <div class="group">
      <div class="title" [class.disabled]="!templatesByGroup[group]?.length" (click)="toggleSelectedGroup(group)">
        <span>{{ group }}</span>
        <coin-v2-divider orientation="horizontal"></coin-v2-divider>
        <mat-icon>{{ (activeGroup$ | async) === group ? 'expand_more' : 'chevron_right' }}</mat-icon>
      </div>
      @if ((activeGroup$ | async) === group) {
        <div class="items">
          @for (templates of templatesByGroup[group]; track templates) {
            <ng-container *ngTemplateOutlet="templates"></ng-container>
          }
        </div>
      }
    </div>
  }
}
