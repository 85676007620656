@if (heads$ | async; as heads) {
  @for (head of heads; track head; let i = $index) {
    <coin-c-org-tree-overview-item
      [head]="head"
      [level]="level"
      [last]="heads.length - 1 === i"
      [lastParent]="lastParent"
      [selectedEmployeeId]="selectedEmployeeId"
      [customOpenEmployeeInViewAction]="customOpenEmployeeInViewAction"
      [service]="service"
      [seasonId]="seasonId"
      (clickTreeItem)="itemSelect.emit()">
    </coin-c-org-tree-overview-item>
  }
}
