export enum ReassingmentColumn {
  Reason = 'Reason',
  Plan = 'Plan',
  PlaceofAction = 'PlaceOfAction',
  Are = 'Are',
  OrgCluster = 'OrgCluster',
  ManagerType = 'ManagerType',
  TiaLocal = 'TiaLocal',
  Ipt = 'Ipt'
}
