<div class="container">
  <div class="search">
    <coin-input
      addClass="shadow"
      type="text"
      [(ngModel)]="searchEmployee"
      (input)="searchInputChanged()"
      placeholder="{{ 'main-menu.directs-search' | translate }}"
      label="Search"
      svgIcon="search">
    </coin-input>
  </div>

  <ngx-simplebar class="content-container primary-scrollbar-vertical no-space">
    @if (displayedEmployeeList?.length > 0) {
      <div class="directs-overview-scroll-viewport" intersectionObserverList>
        @for (employee of displayedEmployeeList; track employee) {
          <coin-siemens-energy-employee-preview
            class="directs-overview-elements"
            [useIntersectionObserver]="true"
            (click)="goToDocumentCenter(employee)"
            [employee]="employee"
            intersectionObserverElement>
          </coin-siemens-energy-employee-preview>
        }
      </div>
    } @else {
      <p>{{ 'main-menu.no-directs-found' | translate }}</p>
    }
  </ngx-simplebar>
</div>
