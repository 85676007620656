@if (item) {
  <div style="width: 100%; position: relative" class="main-slider-body">
    <div class="slider-text-underlay" [ngClass]="{ 'underlay-full-width': item.fullWidth }"></div>
    @if (contentEdit) {
      <div class="action-button-container">
        <coin-action-button (click)="deleteSliderItem()" class="secondary" [icon]="'close'"></coin-action-button>
      </div>
    }
    <drag-scroll
      style="min-width: 100%"
      [ngClass]="{ 'slider-full-width': item.fullWidth }"
      [snap-duration]="1000"
      [drag-scroll-y-disabled]="true"
      (indexChanged)="indexChanged()"
      #nav>
      @for (image of item.sliderImages; track image) {
        <img aria-label="slider image" drag-scroll-item [src]="image.img" />
      }
    </drag-scroll>
    @if (contentEdit) {
      <div class="action-button-container add">
        <coin-action-button (click)="addSliderItem()" class="primary" [icon]="'add'">Add new slider</coin-action-button>
      </div>
    }
    @if (item.sliderImages.length > 1) {
      <div class="slider-indicator">
        @for (image of item.sliderImages; track image; let i = $index) {
          <div (click)="moveTo(i)" class="slider-dot" [ngStyle]="{ 'background-color': i == sliderIndex ? 'white' : 'hsla(0,0%,100%,.35)' }"></div>
        }
        <!-- <i class="material-icons" (click)="moveTo(i)" *ngFor="let image of item.sliderImages; let i = index">{{i == sliderIndex ? 'panorama_fish_eye' : 'fiber_manual_record'}}</i> -->
      </div>
    }
    <div class="slider-text">
      <div style="background: linear-gradient(180deg, var(--purple-contrast600), var(--purple700)); width: 6px; position: absolute; left: 0; top: 0.3rem; bottom: 0.6rem"></div>
      @if (!contentEdit) {
        <h2>{{ item.sliderImages[sliderIndex].text }}</h2>
      }
      @if (contentEdit) {
        <h2 [contentEditable]="contentEdit" [textContent]="item.sliderImages[sliderIndex].text" (input)="item.sliderImages[sliderIndex].text = $event.target.textContent"></h2>
      }
      @if (!contentEdit) {
        <p style="position: absolute; color: white; font-size: 1em">{{ item.sliderImages[sliderIndex].subtext }}</p>
      }
      @if (contentEdit) {
        <p
          [contentEditable]="contentEdit"
          [textContent]="item.sliderImages[sliderIndex].subtext"
          (input)="item.sliderImages[sliderIndex].subtext = $event.target.textContent"
          style="position: absolute; color: white; font-size: 1em"></p>
      }
    </div>
    @if (sliderIndex != 0) {
      <i class="material-icons direction direction--left" (click)="moveLeft()">keyboard_arrow_left</i>
    }
    @if (sliderIndex != item.sliderImages.length - 1) {
      <i class="material-icons direction direction--right" (click)="moveRight()">keyboard_arrow_right</i>
    }
  </div>
}

@if (contentEdit) {
  <div class="inputs" style="margin-top: -45px; margin-left: 10px">
    @if (contentEdit) {
      <coin-action-button (click)="editPicture()" class="secondary" svgIcon="edit" [noicon]="true"> {{ 'cms.content-pages.change-image-button' | translate }}</coin-action-button>
    }
  </div>
}
