<div style="padding: 20px 0px; margin-top: 20px">
  @if (!contentEdit) {
    <h3>{{ item?.headline }}</h3>
  }
  @if (contentEdit) {
    <h3 [contentEditable]="contentEdit" [textContent]="item?.headline" (input)="item.headline = $event.target.textContent"></h3>
  }

  <div class="mod-image-quote mod-image-quote--light mod-image-quote--left mod-image-quote--background">
    <div class="m-content" style="padding: 20px 0px 30px 0px">
      <div class="m-text-content">
        @if (!contentEdit) {
          <p class="m-quote blockquote" [innerHTML]="item?.text"></p>
        }
        @if (contentEdit) {
          <app-html-editor [item]="item" [contentEdit]="true" [isNews]="true"></app-html-editor>
        }
        <!-- <p class="m-quote blockquote" *ngIf="contentEdit" [contentEditable]="contentEdit" [textContent]="item.text" (input)="item.text = $event.target.textContent"></p> -->
      </div>
    </div>
  </div>
</div>
