import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpenFaqService {
  openClose$ = new Subject<boolean>();

  public faqManage(type: boolean): void {
    this.openClose$.next(type);
  }
}
