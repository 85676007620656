@if (item) {
  <div style="position: relative">
    <div
      style="display: flex; max-width: 100vw"
      [style.overflow]="overflow"
      cdkDropList
      (cdkDropListDropped)="drop($event, item.pictureContainers)"
      cdkDropListOrientation="horizontal"
      class="picture-cont-drag-list">
      @for (subitem of item.pictureContainers; track subitem; let i = $index) {
        <div
          [cdkDragDisabled]="!contentEdit"
          class="item-container picture-cont-drag-box picture-cont-drag-box--{{ containerLength }}"
          [ngClass]="{ 'last-pic': containerLength - 1 === i, 'mobile-view': isMobile }"
          cdkDrag
          #item>
          @if (contentEdit) {
            <span class="material-icons" cdkDragHandle style="position: absolute; top: 10px; left: 10px; cursor: pointer; z-index: 10; color: white">drag_indicator</span>
          }
          @if (contentEdit) {
            <div class="action-button-container">
              <coin-action-button (click)="deleteItem(i)" class="secondary" icon="close"></coin-action-button>
            </div>
          }
          <div class="pic-container">
            <div style="width: 100%; overflow: hidden; min-height: 350px; height: 33vh; max-height: 550px">
              <div class="pic-image" [ngStyle]="{ background: 'url(' + subitem.img + ')' }">
                <div class="pic-text" [ngStyle]="{ height: heightModified ? 'calc(100% - 40px)' : '100%' }">
                  <div class="underlay-full-width"></div>
                  <div style="flex: 1"></div>
                  <div style="position: relative">
                    <div
                      style="width: 3px; position: absolute; left: 0; top: 0.3rem; bottom: 0.3rem; background: linear-gradient(180deg, var(--purple700), var(--purple700))"></div>
                    @if (!contentEdit) {
                      <h3 style="margin-bottom: 0px; margin-left: 15px; margin-top: 0px">{{ subitem.mainTitle }}</h3>
                    }
                    @if (contentEdit) {
                      <h3
                        style="margin-bottom: 0px; margin-left: 15px; margin-top: 0px"
                        [contentEditable]="contentEdit"
                        [textContent]="subitem.mainTitle"
                        (input)="subitem.mainTitle = $event.target.textContent"></h3>
                    }
                  </div>
                  @if (!contentEdit) {
                    <p style="margin-top: 16px; margin-bottom: 16px; min-height: 100px">{{ subitem.text }}</p>
                  }
                  @if (contentEdit) {
                    <p
                      style="margin-top: 16px; margin-bottom: 16px; min-height: 100px"
                      [contentEditable]="contentEdit"
                      [textContent]="subitem.text"
                      (input)="subitem.text = $event.target.textContent"></p>
                  }
                </div>
              </div>
            </div>
          </div>
          <div class="inputs">
            <!-- <mat-form-field *ngIf="contentEdit">
          <mat-label>{{'cms.image-url' | translate}}</mat-label>
          <input matInput aria-label="image url" [(ngModel)]="subitem.img" [placeholder]="'https://...'" [disabled]="!contentEdit" type="test">
      </mat-form-field> -->
            @if (contentEdit) {
              <mat-form-field>
                <mat-label>{{ 'cms.link-url' | translate }}</mat-label>
                <input matInput aria-label="image url" [(ngModel)]="subitem.link" [placeholder]="'https://...'" [disabled]="!contentEdit" type="test" />
              </mat-form-field>
            }
            @if (contentEdit) {
              <coin-action-button (click)="editPicture(i)" class="secondary" svgIcon="edit" [noicon]="true">
                {{ 'cms.content-pages.change-image-button' | translate }}
              </coin-action-button>
            }
            <!-- <gmm-input
          *ngIf="contentEdit"
          [(model)]="subitem.img"
          [placeholder]="'https://...'"
          [label]="'Image url'"
          [disabled]="!contentEdit"
          [required]="true"
          type="test"
          [name]="'imgUrl'"></gmm-input> -->
            <!-- <gmm-input
          *ngIf="contentEdit"
          [(model)]="subitem.link"
          [placeholder]="'https://...'"
          [label]="'Link url'"
          [disabled]="!contentEdit"
          [required]="true"
          type="test"
          [name]="'linkUrl'"></gmm-input> -->
          </div>
        </div>
      }
      @if (showArrow('left') && !contentEdit) {
        <div @fadeInHalf class="arrow-container left" [ngClass]="{ 'scroll-opacity': refresh }" (click)="scroll('left')">
          <i class="icon icon--arrow-left"></i>
        </div>
      }
      @if (showArrow('right') && !contentEdit) {
        <div @fadeInHalf class="arrow-container" [ngClass]="{ 'scroll-opacity': refresh }" (click)="scroll('right')">
          <i class="icon icon--arrow-right"></i>
        </div>
      }
      @if (contentEdit) {
        <div class="action-button-container add">
          <coin-action-button (click)="addImage()" class="primary" svgIcon="add" [noicon]="true" [circle]="true"></coin-action-button>
        </div>
      }
    </div>
  </div>
}
