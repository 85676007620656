import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, DestroyRef, DoCheck, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@coin/shared/feature-legacy-components';
import { Subject } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { TinyHelpers } from '@coin/shared/util-helpers';
import { ConfirmationDialogConfirm } from '@coin/shared/util-models';
import { CmsEmailIndexListItemContent, CmsItem, DefaultColorPickerValues } from '@coin/admin/cms/util';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { QUILL_DEFAULT_MODULES } from '../../shared/html-editor/quill-modules.config';
import { EmailIndexListSelectionComponent } from './parts/email-index-list-selection/email-index-list-selection.component';

@Component({
  selector: 'coin-a-email-index-list',
  templateUrl: './email-index-list.component.html',
  styleUrls: ['./email-index-list.component.scss']
})
export class EmailIndexListComponent implements OnInit, DoCheck {
  @Input() item?: CmsItem<'index-list'>;
  @Input() content?: CmsItem[];

  private initialContent: CmsItem[];
  private updateIndexList$ = new Subject<void>();

  public quillSimpleTextModules = QUILL_DEFAULT_MODULES;
  public automaticSyncEnabled: boolean;

  constructor(
    private dialog: MatDialog,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.item ??= {} as CmsItem<'index-list'>;
    this.automaticSyncEnabled = this.item.automaticSyncEnabled;
    this.listenToIndexListUpdate();
    this.setDefaultColors();
  }

  ngDoCheck(): void {
    if (!this.item?.automaticSyncEnabled) {
      return;
    }

    const contentChanged = !TinyHelpers.areItemsSimilar(this.content, this.initialContent);

    if (!contentChanged) {
      return;
    }

    this.initialContent = structuredClone(this.content);
    this.updateIndexList$.next();
  }

  private setDefaultColors(): void {
    this.item.backgroundColor = this.item.backgroundColor || DefaultColorPickerValues.backgroundColor;
    this.item.textColor = this.item.textColor || DefaultColorPickerValues.textColor;
  }

  private listenToIndexListUpdate(): void {
    this.updateIndexList$
      .pipe(debounceTime(500))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.setupIndexList());
  }

  public confirmAutoSync(): void {
    if (this.automaticSyncEnabled && this.item.content?.length > 0) {
      this.dialog
        .open(ConfirmationDialogComponent, {
          disableClose: true,
          data: {
            headline: 'general.confirm',
            translate: true,
            msg: 'cms.remove-item-warning-msg',
            confirmMsg: 'general.confirm',
            cancelMsg: 'general.go-back'
          }
        })
        .afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((isConfirm: ConfirmationDialogConfirm) => {
          if (!isConfirm) {
            this.automaticSyncEnabled = false;
          }

          this.item.automaticSyncEnabled = this.automaticSyncEnabled;
        });
    } else {
      this.item.automaticSyncEnabled = this.automaticSyncEnabled;
    }
  }

  private setupIndexList(): void {
    const headlineItems = this.content?.filter(item => item.type === 'email-headline');
    const existingItemContent = structuredClone(this.item.content);

    this.item.content = headlineItems?.map(item => {
      const existingItem = existingItemContent.find(existingItem => existingItem.referenceId === item.id);
      const referenceTitle = TinyHelpers.removeHtmlTags(item.content as string);
      const referenceDefaultDisplayTitle = `<p> ${referenceTitle}</p>`;
      return {
        referenceTitle,
        referenceId: item.id,
        title: existingItem?.title ?? referenceDefaultDisplayTitle
      };
    });
  }

  public trackByIndexItemFn(index: number, item: CmsEmailIndexListItemContent): string | number {
    return item.referenceId ?? index;
  }

  public drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.item.content, event.previousIndex, event.currentIndex);
  }

  public deleteItem(itemContent: CmsEmailIndexListItemContent): void {
    this.item.content = this.item.content.filter(existingItemContent => existingItemContent.referenceId !== itemContent.referenceId);
  }

  public addItem(): void {
    this.dialog
      .open(EmailIndexListSelectionComponent, {
        data: {
          content: this.content,
          selectedItemReferenceIds: this.item.content?.map(item => item.referenceId)
        },
        height: '70vh',
        width: '50vw',
        disableClose: true
      })
      .afterClosed()
      .pipe(
        filter(item => !!item),
        tap((item: CmsItem) =>
          this.item.content.push({
            referenceTitle: item.type,
            referenceId: item.id,
            title: ''
          })
        )
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
