<mat-form-field
  [matTooltip]="errorTooltip$ | async"
  [matTooltipDisabled]="!touched() || !showErrorTooltip"
  [class.rtl]="direction === 'rtl'"
  matTooltipClass="error-tooltip"
  class="lib-input coin-form-field-outline coin-form-field-outline--{{ matFormFieldClass }} no-margin"
  appearance="outline"
  floatLabel="always">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  @if (prefix) {
    <ng-container matPrefix>
      @if (prefix | typeof: 'string') {
        <p>{{ prefix }}</p>
      } @else {
        <ng-container *ngTemplateOutlet="prefix"></ng-container>
      }
    </ng-container>
  }
  <input
    #textInput
    type="text"
    (blur)="onTouch(value)"
    [placeholder]="placeholder"
    [matAutocompleteDisabled]="!autoCompleteOptions?.length"
    [matAutocomplete]="autoComplete"
    matInput
    [ngModel]="value"
    (ngModelChange)="setValueDebounced$.next($event)"
    [disabled]="disabled"
    [dir]="direction"
    autocomplete="off" />
  @if (clearable && !loading) {
    <button (click)="onClear()" class="no-style">
      <mat-icon>close</mat-icon>
    </button>
  } @else {
    <!-- TODO: use animated loading icon -->
    @if (icon || loading) {
      <mat-icon>{{ loading ? 'hourglass_empty' : icon }}</mat-icon>
    }
  }

  <mat-autocomplete #autoComplete="matAutocomplete">
    @for (option of autoCompleteOptions; track option) {
      <mat-option [value]="option">{{ option }}</mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
