<div>
  <div class="single-double-main">
    @for (image of item?.content; track image) {
      <div class="flex-one">
        <div
          class="single-double-image"
          [ngClass]="{ 'multiple-images': item?.content?.length > 1 }"
          [style.background-image]="'url(' + image.link + ')'"
          [style.height.px]="item?.height"
          [style.max-height.vh]="isResponsive ? 20 : ''"></div>
        <div class="single-double-button">
          @if (contentEdit) {
            <coin-action-button (click)="editPicture(image)" class="secondary single-double-button-item" svgIcon="edit" [noicon]="true">
              {{ 'cms.emails.change-image-button' | translate }}
            </coin-action-button>
          }
          @if (contentEdit && editImageLink) {
            <coin-input [(ngModel)]="image.anchorLink" addClass="shadow" label="Image URL"></coin-input>
          }
        </div>
      </div>
    }
  </div>
</div>

<div style="display: flex; gap: 8px">
  @if (contentEdit) {
    <mat-form-field appearance="fill">
      <mat-label>{{ 'cms.amount-images' | translate }}</mat-label>
      <mat-select (selectionChange)="amountChange($event.value)" [(ngModel)]="amountImages">
        @for (option of amountOptions; track option) {
          <mat-option [value]="option">{{ option }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  }

  @if (contentEdit && item) {
    <coin-v2-number-input [label]="'cms.content-pages.image-height' | translate" aria-label="image height" [(ngModel)]="item.height" [placeholder]="'150'" />
  }
</div>
@if (contentEdit && showBackgroundPicker && item) {
  <div class="background-color-picker">
    <coin-color-picker [(ngModel)]="item.backgroundColor"></coin-color-picker>
  </div>
  <coin-a-email-padding-slider [(ngModel)]="item.paddingTop" label="Space-Top"></coin-a-email-padding-slider>
  <coin-a-email-padding-slider [(ngModel)]="item.paddingBottom" label="Space-Bottom"></coin-a-email-padding-slider>
}
