import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@coin/shared/util-environments';
import { CmsItem } from '@coin/admin/cms/util';
import { shrinkExpandAllAnimation } from '@coin/shared/util-animations';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-equity-expand-linksbox',
  templateUrl: './equity-expand-linksbox.component.html',
  styleUrls: ['./equity-expand-linksbox.component.scss'],
  animations: [shrinkExpandAllAnimation]
})
export class EquityExpandLinksboxComponent implements OnInit {
  @Input() item: CmsItem<'expand-linksbox'>;
  @Input() contentEdit = false;

  public boxExpanded = false;
  public imagePath = environment.coinImagePath;
  public expandSearch = '';

  private searchDelay: number;
  private allLinksHolder: typeof this.item.allLinks = [];

  ngOnInit(): void {
    if (this.item?.allLinks) {
      this.allLinksHolder = structuredClone(this.item?.allLinks);
    }
  }

  public applyAmountLinks(): void {
    if (this.item.amountLinks < this.item.allLinks.length) {
      this.item.allLinks = this.item.allLinks.slice(0, this.item.amountLinks);
    } else if (this.item.amountLinks > this.item.allLinks.length) {
      for (let i = this.item.allLinks.length; i < this.item.amountLinks; i++) {
        this.item.allLinks.push({
          text: `Link Text ${i + 1}`,
          link: 'https://www.google.de'
        });
      }
    }
  }

  public deleteItem(i: number): void {
    this.item.allLinks.splice(i, 1);
  }

  /**
   * Search for items after last input (600ms delay)
   * @param input search string user input
   */
  public searchAllLinks(input: Event): void {
    clearTimeout(this.searchDelay);
    this.searchDelay = window.setTimeout(() => {
      this.filterForSearch((input.target as HTMLInputElement).value);
    }, 600);
  }

  /**
   * search function that is triggered after last input +600ms
   * @param input search string
   */
  public filterForSearch(input: string): void {
    let newLinks = structuredClone(this.allLinksHolder);
    if (input) {
      newLinks = newLinks.filter(itm => itm.text.toLowerCase().indexOf(input.toLowerCase()) > -1);
    }
    this.item.allLinks = newLinks;
  }

  /**
   * open link in a new tab that will close automatically after download
   * @param link string
   */
  public openLink(link: string): void {
    const win = window.open(link + (link.indexOf('/document-download/') > -1 ? '--close' : ''));
    win.addEventListener('load', () => {
      setTimeout(() => {
        win.close();
      }, 5000);
    });
  }
}
