import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MeritPromotionState } from '@coin/customer/merit-incentive/util';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { BudgetAllocationSettings, Employee, PaginatedResult, SearchItemService, MeritAllocationSeason } from '@coin/shared/util-models';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { FilterSortState } from '@coin/customer/shared/filter-sort-bar-data-access';
import { MeritBudgetAllocationState } from '../../merit-budget-allocation/store/merit-budget-allocation.state';
import { MeritBudgetResponsibilityType } from '../enums/merit-budget-responsibility-type.enum';
import { IncentiveAllocationEmployee } from '../models/incentive-allocation-employee.model';
import { MeritAllocationSnapshot, MeritEmployeeSnapshotSlim } from '../models/merit-allocation-snapshot.model';
import { MeritBudgetCompensationInfo } from '../models/merit-budget-compensation-info.model';
import { EmployeeWithCompensationInfo } from '../models/merit-budget-direct-with-compensation.model';
import { PromotionBudgetResult } from '../models/merit-promotion-list.model';
import { PromotionBudgetOverview } from '../models/merit-promotion-overview.model';
import { UpdateAllocationValuesDto } from '../models/update-allocation-values-dto.model';
import { MeritAllocationSnapshotMapOperations } from '../operations/merit-allocation-snapshot-map.operations';
import { AllocationOrgDetailsService } from './allocation-org-details-mapping.service';
import { MeritAllocationImpersonatedEmployeeService } from './merit-allocation-impersonated-employee.service';
import { UpdateAllocationValuesService } from './update-allocation-values.service';

const DEFAULT_PAGE_SIZE = 100_000;

@Injectable()
export class MeritAllocationGeneralService implements AllocationOrgDetailsService, SearchItemService {
  public selectedSeasonIdForSearch: string;

  constructor(
    private httpClient: HttpClient,
    private httpHelpersService: HttpHelpersService,
    private loadingService: LoadingService,
    private updateAllocationValuesService: UpdateAllocationValuesService,
    private toast: ToastrService,
    private router: Router,
    private meritAllocationImpersonatedEmployeeService: MeritAllocationImpersonatedEmployeeService,
    private store: Store
  ) {}

  getTotalAllocations(page: number, size: number, seasonId: string, filterText = ''): Observable<PaginatedResult<EmployeeWithCompensationInfo>> {
    this.loadingService.present();
    return this.httpClient
      .get<
        PaginatedResult<MeritAllocationSnapshot>
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/total?page=${page}&size=${size}${filterText}${this.meritAllocationImpersonatedEmployeeService.getEmployeeIdQuery()}`)
      .pipe(
        map(data => ({ ...data, content: MeritAllocationSnapshotMapOperations.mapSnapshotsToEmployees(data.content) })),
        this.httpHelpersService.handleError('Cannot get allocation members'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  getAllocationMember(seasonId: string, employeeId: string, orgCode?: string): Observable<MeritBudgetCompensationInfo> {
    const separator = this.meritAllocationImpersonatedEmployeeService.employeeId ? '&' : '?';
    const query = orgCode ? `${separator}orgCode=${encodeURIComponent(orgCode)}` : '';
    return this.httpClient
      .get<MeritAllocationSnapshot>(
        `${
          environment.api.baseUrl
        }/admin/api/v2/master/seasons/merit/${seasonId}/allocations/total/${employeeId}${this.meritAllocationImpersonatedEmployeeService.getEmployeeIdQuery('?')}${query}`
      )
      .pipe(
        map(info => ({ ...info, compensationComponents: MeritAllocationSnapshotMapOperations.mapCompensationComponents(info.compensationComponents) })),
        catchError(() => of(null))
      );
  }

  getEmployeeSnapshotById(seasonId: string, employeeId: string): Observable<MeritEmployeeSnapshotSlim> {
    return this.loadingService.withLoadingScreen(
      this.httpClient
        .get<MeritEmployeeSnapshotSlim>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/${seasonId}/snapshots/${employeeId}`)
        .pipe(this.httpHelpersService.handleError('Cannot get snapshot'))
    );
  }

  getSearchItemsByText(text: string, page: number): Observable<Employee[]> {
    const filterText = this.store.selectSnapshot(FilterSortState.getFilterText);
    return this.httpClient
      .get<
        PaginatedResult<Employee>
      >(`${environment.api.baseUrl}/admin/api/v3/master/seasons/snapshots/employees?page=${page + 1}&size=50&search=${encodeURIComponent(text)}${this.router.url.includes('list-view') ? '&directs=true' : ''}&meritSeasonId=${this.selectedSeasonIdForSearch}${this.meritAllocationImpersonatedEmployeeService.getEmployeeIdQuery()}${filterText}`)
      .pipe(
        map(res => res.content),
        this.httpHelpersService.handleError('Cannot get employees by search text')
      );
  }

  getMeritPartnerSeasons(): Observable<MeritAllocationSeason[]> {
    this.loadingService.present();
    return this.httpClient
      .get<PaginatedResult<MeritAllocationSeason>>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/partner/seasons?page=1&size=${DEFAULT_PAGE_SIZE}`)
      .pipe(
        map(data => data.content),
        this.httpHelpersService.handleError('Cannot get merit seasons'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  getMeritAllocationSeasons(): Observable<MeritAllocationSeason[]> {
    this.loadingService.present();
    return this.httpClient
      .get<PaginatedResult<MeritAllocationSeason>>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/allocations?page=1&size=${DEFAULT_PAGE_SIZE}`)
      .pipe(
        map(data => data.content),
        this.httpHelpersService.handleError('Cannot get merit seasons'),
        finalize(() => this.loadingService.dismiss())
      );
  }
  getMeritSeasonSettings(seasonId: string): Observable<BudgetAllocationSettings> {
    this.loadingService.present();
    return this.httpClient.get<BudgetAllocationSettings>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/${seasonId}/merit-allocation-settings`).pipe(
      this.httpHelpersService.handleError('Cannot load budget allocation settings'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  // employeeId is really employeeId and not allocation member id
  updateDirect(seasonId: string, employeeId: string, body: UpdateAllocationValuesDto): Observable<UpdateAllocationValuesDto> {
    return this.loadingService.withLoadingScreen(
      this.httpClient
        .put<UpdateAllocationValuesDto>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/values/${employeeId}`, body)
        .pipe(this.httpHelpersService.handleError('Cannot update values'))
    );
  }

  public promoteEmployee(seasonId: string, employeeId: string, comment: string): Observable<void> {
    this.loadingService.present();
    return this.httpClient
      .patch<void>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/${employeeId}/promote`, {
        state: MeritPromotionState.Requested,
        comment
      })
      .pipe(
        this.httpHelpersService.handleError('Cannot promote employee'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  undoEmployeePromotion(seasonId: string, employeeId: string): Observable<void> {
    this.loadingService.present();
    return this.httpClient
      .patch<void>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/${employeeId}/reset-promotion`, {
        seasonId,
        employeeId
      })
      .pipe(
        this.httpHelpersService.handleError('Cannot undo promotion'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  approveEmployee(seasonId: string, employeeId: string): Observable<void> {
    this.loadingService.present();
    return this.httpClient.patch<void>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/${employeeId}/approve`, {}).pipe(
      this.httpHelpersService.handleError('Cannot approve employee'),
      finalize(() => this.loadingService.dismiss())
    );
  }

  getFieldValues(page: number, size: number, key: string, searchText: string, seasonId: string, parentOrgCode: string): Observable<PaginatedResult<string>> {
    let searchQuery = searchText?.trim() ? `&${key}=${searchText}` : '';
    const responsibility = this.store.selectSnapshot(MeritBudgetAllocationState.responsibilityType);
    const isDirect = responsibility === MeritBudgetResponsibilityType.DirectResponsibility;
    const pathSegment = isDirect ? 'directs' : 'totals';
    if (!isDirect) {
      searchQuery += `&ParentOrgCode=${encodeURIComponent(parentOrgCode)}`;
    }

    return this.httpClient
      .get<
        PaginatedResult<string>
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/${pathSegment}/field-values?Page=${page + 1}&Size=${size}&targetProperty=${key}${searchQuery}${this.meritAllocationImpersonatedEmployeeService.getEmployeeIdQuery()}`)
      .pipe(catchError(() => of(null)));
  }

  updateDirects(directs: EmployeeWithCompensationInfo[], updateBody: Partial<EmployeeWithCompensationInfo>, seasonId: string): Observable<UpdateAllocationValuesDto[]> {
    const requests$ = directs.map(direct =>
      from(this.updateAllocationValuesService.getUpdateBody({ ...direct, ...updateBody })).pipe(switchMap(updateBody => this.updateDirect(seasonId, direct.employeeId, updateBody)))
    );

    this.loadingService.present();
    return forkJoin(requests$).pipe(
      tap(() => this.toast.success(`${directs.length} items updated.`)),
      finalize(() => this.loadingService.dismiss())
    );
  }

  flagMeritAllocation(seasonId: string, allocationMemberId: string, isFlagged: boolean): Observable<EmployeeWithCompensationInfo | IncentiveAllocationEmployee> {
    this.loadingService.present();
    return this.httpClient
      .put<
        EmployeeWithCompensationInfo | IncentiveAllocationEmployee
      >(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/${allocationMemberId}/flag`, { isFlagged })
      .pipe(
        this.httpHelpersService.handleError('Cannot flag employee'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  getPromotionOverview(seasonId: string, orgCode: string, filterText = ''): Observable<PromotionBudgetOverview> {
    const impersonatedEmployee = this.meritAllocationImpersonatedEmployeeService.employeeId;
    let params = new HttpParams({ fromString: filterText }).appendAll({ orgCode }).delete('');
    if (impersonatedEmployee) params = params.append('impersonatedEmployee', impersonatedEmployee);

    return this.httpClient
      .get<PromotionBudgetOverview>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/promotions/overview`, {
        params
      })
      .pipe(this.httpHelpersService.handleError('Cannot get promotion overview'), this.loadingService.withLoadingScreen);
  }

  getPromotionList(seasonId: string, orgCode: string, filterText = ''): Observable<PromotionBudgetResult> {
    const impersonatedEmployee = this.meritAllocationImpersonatedEmployeeService.employeeId;
    let params = new HttpParams({ fromString: filterText }).appendAll({ orgCode }).delete('');
    if (impersonatedEmployee) params = params.append('impersonatedEmployee', impersonatedEmployee);

    return this.httpClient
      .get<PromotionBudgetResult>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/merit/${seasonId}/allocations/promotions`, {
        params
      })
      .pipe(this.httpHelpersService.handleError('Cannot get promotion list'), this.loadingService.withLoadingScreen);
  }

  public getAllocationSettings(seasonId: string): Observable<BudgetAllocationSettings> {
    this.loadingService.present();
    return this.httpClient.get<BudgetAllocationSettings>(`${environment.api.baseUrl}/admin/api/v2/master/seasons/${seasonId}/merit-allocation-settings`).pipe(
      this.httpHelpersService.handleError('Cannot load budget allocation settings'),
      finalize(() => this.loadingService.dismiss())
    );
  }
}
