<div class="container" [ngStyle]="{ 'max-height': maxHeight }">
  <div class="fv-basic" [ngClass]="{ needsColumn: needsColumn }">
    @if (text) {
      <div class="fv-text">
        <h3>{{ text.headline | translate }}</h3>
        <p [innerHTML]="text.text | translate"></p>
      </div>
    }
    @if (image) {
      <div class="fv-image">
        <div></div>
        <video [src]="image" [ngStyle]="{ 'max-width': imageMaxWidth }" [controls]="false" [autoplay]="true" [loop]="true">
          <source [src]="image" type="video/mov" />
        </video>
        <div></div>
        <!-- <img aria-label="first user experience gif" *ngIf="image" [src]="image"/> -->
      </div>
    }
  </div>
</div>
