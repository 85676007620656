import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { DirectsState, GetDirects, RoleGuard, UserState } from '@coin/modules/auth/data-management';
import { StorageService } from '@coin/shared/data-access';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { filter, map, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerRoleGuard extends RoleGuard {
  constructor(router: Router, store: Store, ngZone: NgZone, toast: ToastrService, storageService: StorageService) {
    super(router, store, ngZone, toast, storageService);
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(UserState.user).pipe(
      filter(Boolean),
      switchMap(user => this.store.dispatch(new GetDirects({ userId: user.id }))),
      map(() => this.directAllowedAccess(route)),
      switchMap(canActivate => (canActivate ? of(true) : super.canActivate(route)))
    );
  }

  private directAllowedAccess(route: ActivatedRouteSnapshot): boolean {
    return !!(route.data?.directAllowAccessLineManager && this.store.selectSnapshot(DirectsState.isLineManager));
  }
}
