@if (contentEdit) {
  <div class="expand-box-edit">
    <mat-form-field class="expand-box-input">
      <mat-label>{{ 'cms.content-pages.headline-text' | translate }}</mat-label>
      @if (item) {
        <input matInput aria-label="image headline" [(ngModel)]="item.headline" [placeholder]="'cms.content-pages.headline-text' | translate" type="text" />
      }
    </mat-form-field>
    <mat-form-field class="expand-box-input">
      <mat-label>{{ 'cms.content-pages.icon' | translate }}</mat-label>
      <input matInput aria-label="icon" [(ngModel)]="item.icon" [placeholder]="'cms.content-pages.icon' | translate" type="text" />
    </mat-form-field>
  </div>
}

<div class="expand-box-main">
  <div class="expand-box-item" (click)="boxExpanded = !boxExpanded">
    <div class="expand-box-item-content">
      @if (item?.icon) {
        <span class="material-icons-outlined">{{ item?.icon }}</span>
      }
      <p>{{ item?.headline }}</p>
      <span class="material-icons-outlined">expand_more</span>
    </div>
  </div>
  <div class="expand-box-content" [ngClass]="{ 'expand-box-content--expanded': boxExpanded || contentEdit }">
    @if (!contentEdit) {
      <div [innerHTML]="item?.text"></div>
    }
    @if (contentEdit) {
      <app-html-editor [item]="item" [contentEdit]="true" [isNews]="true"></app-html-editor>
    }
  </div>
</div>
