import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CmsComponentsModule } from '@coin/admin/cms/feature-preview';
import { SubComponentHeaderComponent } from '@coin/customer/common/ui';
import { DirectsState } from '@coin/modules/auth/data-management';
import { AuthComponentsModule } from '@coin/modules/auth/feature';
import { CustomerSurveyModule } from '@coin/modules/customer-survey/feature';
import { AppEnvironment, CustomTranslationLoader, TranslationDebugParser } from '@coin/shared/data-access-translation-loader';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { V2DialogModule, V2TextAreaComponent } from '@coin/shared/ui-storybook';
import { IntersectionObserverElementDirective, IntersectionObserverListDirective } from '@coin/shared/util-directives';
import { EmployeeNamePipe, NormalizedEntityRecordPipe, PureFunctionPipe, ThousandsSeparatorPipe } from '@coin/shared/util-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';
import { SimplebarAngularModule } from 'simplebar-angular';
import { LoadingModule } from '@coin/shared/data-access';
import { FilterSortState } from '@coin/customer/shared/filter-sort-bar-data-access';
import { DocumentsState } from '../document-center/store';
import { DynamicSpacerComponent } from '../equity/components/dynamic-spacer/dynamic-spacer.component';
import { EquityCardsComponent } from '../equity/components/equity-cards/equity-cards.component';
import { EquityCardItemComponent } from '../equity/components/equity-cards/parts/equity-card-item/equity-card-item.component';
import { EquityChapterIconComponent } from '../equity/components/equity-chapter-icon/equity-chapter-icon.component';
import { EquityChapterIndicatorComponent } from '../equity/components/equity-chapter-indicator/equity-chapter-indicator.component';
import { EquityChapterComponent } from '../equity/components/equity-chapter/equity-chapter.component';
import { EquityCountryInfoComponent } from '../equity/components/equity-country-info/equity-country-info.component';
import { EquityDownloadComponent } from '../equity/components/equity-download/equity-download.component';
import { EquityElementNotFoundComponent } from '../equity/components/equity-element-not-found/equity-element-not-found.component';
import { EquityHeadlineComponent } from '../equity/components/equity-headline/equity-headline.component';
import { EquityMediaComponent } from '../equity/components/equity-media/equity-media.component';
import { EquitySubTitleComponent } from '../equity/components/equity-sub-title/equity-sub-title.component';
import { EquityTextComponent } from '../equity/components/equity-text/equity-text.component';
import { EquityTilesComponent } from '../equity/components/equity-tiles/equity-tiles.component';
import { EquityTileItemComponent } from '../equity/components/equity-tiles/parts/equity-tile-item/equity-tile-item.component';
import { EquityPageDataDirective } from '../equity/directives/equity-page-data.directive';
import { TopicActionBarModule } from '../home/components/topic-action-bar/topic-action-bar.module';
import { AppsComponent } from './components/apps/apps.component';
import { AppItemOverviewComponent } from './components/apps/parts/app-item-overview/app-item-overview.component';
import { ArrowToggleButtonComponent } from './components/arrow-toggle-button/arrrow-toggle-button.component';
import { CompensationDeviationComponent } from './components/compensation-deviation/compensation-deviation.component';
import { ComponentTooltipComponent } from './components/component-tooltip/component-tooltip.component';
import { DialogTabHeaderComponent } from './components/dialog-tab-header/dialog-tab-header.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogContentComponent } from './components/dialog/parts/dialog-content/dialog-content.component';
import { DirectsOverviewTabViewComponent } from './components/directs-overview/directs-overview-tab-view/directs-overview-tab-view.component';
import { DirectsOverviewComponent } from './components/directs-overview/directs-overview.component';
import { DirectsComponent } from './components/directs/directs.component';
import { DirectItemOverviewComponent } from './components/directs/parts/direct-item-overview/direct-item-overview.component';
import { GenderScaleComponent } from './components/gender-scale/gender-scale.component';
import { ListViewFilterBarComponent } from './components/list-view-filter-bar/list-view-filter-bar.component';
import { OrgTreeOverviewItemComponent } from './components/org-tree-overview/org-tree-overview-item/org-tree-overview-item.component';
import { OrgTreeOverviewItemsComponent } from './components/org-tree-overview/org-tree-overview-items/org-tree-overview-items.component';
import { OrgTreeOverviewComponent } from './components/org-tree-overview/org-tree-overview.component';
import { PositionClassBadgeComponent } from './components/position-class-badge/position-class-badge.component';
import { PositionClassBarComponent } from './components/position-class-bar/position-class-bar.component';
import { DirectsPositionComparisonListComponent } from './components/position-comparison-side-bar/directs-position-comparison-list/directs-position-comparison-list.component';
import { PositionComparisationFilterListComponent } from './components/position-comparison-side-bar/position-comparisation-filter-list/position-comparisation-filter-list.component';
import { PositionComparisonSideBarComponent } from './components/position-comparison-side-bar/position-comparison-side-bar.component';
import { PositionJobCodeCardComponent } from './components/position-job-code-card/position-job-code-card.component';
import { PositionJobCodeListComponent } from './components/position-job-code-list/position-job-code-list.component';
import { PositionListItemTagsComponent } from './components/position-list-item-tags/position-list-item-tags.component';
import { PositionSurveyResultComponent } from './components/position-survey-result/position-survey-result.component';
import { PositionSurveyResultsComponent } from './components/position-survey-results/position-survey-results.component';
import { PositionsOverlayComponent } from './components/positions-overlay/positions-overlay.component';
import { CollapsedBarComponent } from './components/sidebar/parts/collapsed-bar/collapsed-bar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SimpleBadgeComponent } from './components/simple-badge/simple-badge.component';
import { TabSelectionComponent } from './components/tab-selection/tab-selection.component';
import { GuestAccessComponent } from './dialogs/guest-access/guest-access.component';
import { LoginComponent } from './dialogs/login/login.component';
import { NewsPopupComponent } from './dialogs/news-popup/news-popup.component';
import { NotificationComponent } from './dialogs/notification/notification.component';
import { SelectDialogComponent } from './dialogs/select-dialog/select-dialog.component';
import { OrgTreeState } from './modules/org-tree/store/org-tree.state';
import { FileNamePipe } from './pipes/letter-name.pipe';
import { MeritSnapshotIdPipe } from './pipes/merit-employee-snapshot-id.pipe';
import { TagTooltipPipe } from './pipes/tag-tooltip.pipe';
import { MercerSupportDetailsService } from './services/mercer-support-details.service';

@NgModule({
  declarations: [
    OrgTreeOverviewItemComponent,
    OrgTreeOverviewComponent,
    OrgTreeOverviewItemsComponent,
    NotificationComponent,
    LoginComponent,
    TabSelectionComponent,
    ArrowToggleButtonComponent,
    SidebarComponent,
    CollapsedBarComponent,
    AppsComponent,
    AppItemOverviewComponent,
    DirectItemOverviewComponent,
    DirectsComponent,
    DirectsOverviewComponent,
    GuestAccessComponent,
    NewsPopupComponent,
    EquityPageDataDirective,
    EquityCardItemComponent,
    EquityCardsComponent,
    EquityChapterComponent,
    EquityChapterIconComponent,
    EquityChapterIndicatorComponent,
    EquityElementNotFoundComponent,
    EquityHeadlineComponent,
    EquityMediaComponent,
    EquitySubTitleComponent,
    EquityTextComponent,
    EquityTileItemComponent,
    EquityTilesComponent,
    EquityDownloadComponent,
    EquityCountryInfoComponent,
    DynamicSpacerComponent,
    MeritSnapshotIdPipe,
    DirectsOverviewTabViewComponent,
    FileNamePipe,
    DialogTabHeaderComponent,
    PositionClassBadgeComponent,
    SimpleBadgeComponent,
    PositionsOverlayComponent,
    ComponentTooltipComponent,
    DialogComponent,
    DialogContentComponent,
    PositionListItemTagsComponent,
    TagTooltipPipe,
    SelectDialogComponent,
    PositionJobCodeCardComponent,
    PositionJobCodeListComponent,
    PositionSurveyResultComponent,
    PositionClassBarComponent,
    PositionSurveyResultsComponent,
    ListViewFilterBarComponent,
    GenderScaleComponent,
    PositionComparisonSideBarComponent,
    DirectsPositionComparisonListComponent,
    PositionComparisationFilterListComponent,
    CompensationDeviationComponent
  ],
  imports: [
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    CmsComponentsModule,
    MatTooltipModule,
    TopicActionBarModule,
    MatBadgeModule,
    MatTabsModule,
    MatDialogModule,
    MatCardModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatIconModule,
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule.forChild(CustomTranslationLoader.createConfig(AppEnvironment.Customer, TranslationDebugParser)),
    ToastrModule.forRoot(),
    SimplebarAngularModule,
    LegacyComponentsModule,
    NgxsModule.forFeature([DocumentsState, OrgTreeState, FilterSortState, DirectsState]),
    ScrollingModule,
    InfiniteScrollDirective,
    OverlayModule,
    MatButtonToggleModule,
    CustomerSurveyModule,
    MatStepperModule,
    MatExpansionModule,
    IntersectionObserverListDirective,
    IntersectionObserverElementDirective,
    ThousandsSeparatorPipe,
    NormalizedEntityRecordPipe,
    EmployeeNamePipe,
    PureFunctionPipe,
    LoadingModule,
    AuthComponentsModule,
    V2DialogModule,
    SubComponentHeaderComponent,
    V2TextAreaComponent
  ],
  exports: [
    MatTooltipModule,
    SubComponentHeaderComponent,
    AppItemOverviewComponent,
    TopicActionBarModule,
    LoginComponent,
    TabSelectionComponent,
    ArrowToggleButtonComponent,
    SidebarComponent,
    AppsComponent,
    DirectsComponent,
    GuestAccessComponent,
    MatDialogModule,
    EquityPageDataDirective,
    EquityCardItemComponent,
    EquityCardsComponent,
    EquityChapterComponent,
    EquityChapterIconComponent,
    EquityChapterIndicatorComponent,
    EquityElementNotFoundComponent,
    EquityHeadlineComponent,
    EquityMediaComponent,
    EquitySubTitleComponent,
    EquityTextComponent,
    EquityTileItemComponent,
    EquityTilesComponent,
    EquityDownloadComponent,
    EquityCountryInfoComponent,
    DynamicSpacerComponent,
    MeritSnapshotIdPipe,
    OrgTreeOverviewItemComponent,
    OrgTreeOverviewComponent,
    OrgTreeOverviewItemsComponent,
    FileNamePipe,
    DialogTabHeaderComponent,
    PositionClassBadgeComponent,
    SimpleBadgeComponent,
    PositionsOverlayComponent,
    ComponentTooltipComponent,
    DialogComponent,
    PositionListItemTagsComponent,
    TagTooltipPipe,
    PositionJobCodeListComponent,
    PositionSurveyResultsComponent,
    ListViewFilterBarComponent,
    GenderScaleComponent,
    PositionComparisonSideBarComponent,
    CompensationDeviationComponent
  ],
  providers: [MercerSupportDetailsService]
})
export class SharedModule {}
