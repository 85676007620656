/**
 * RestructuringCustomerAPI
 * API for Restructuring customer module.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: tim.weiss@siemens-energy.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RestructuringViewCellModel { 
    rowNumber?: number;
    isEditable?: boolean;
    cellType?: RestructuringViewCellModel.CellTypeEnum;
    content?: string | null;
    attributeId?: string | null;
    variableType?: RestructuringViewCellModel.VariableTypeEnum | null;
    isDropdown?: boolean | null;
    displayType?: RestructuringViewCellModel.DisplayTypeEnum;
    tooltip?: string | null;
}
export namespace RestructuringViewCellModel {
    export type CellTypeEnum = 'Attribute' | 'Text' | 'Matrix' | 'Condition';
    export const CellTypeEnum = {
        Attribute: 'Attribute' as CellTypeEnum,
        Text: 'Text' as CellTypeEnum,
        Matrix: 'Matrix' as CellTypeEnum,
        Condition: 'Condition' as CellTypeEnum
    };
    export type VariableTypeEnum = 'Text' | 'Number' | 'Date' | 'Boolean' | 'Country' | 'Currency' | 'Percentage';
    export const VariableTypeEnum = {
        Text: 'Text' as VariableTypeEnum,
        Number: 'Number' as VariableTypeEnum,
        Date: 'Date' as VariableTypeEnum,
        Boolean: 'Boolean' as VariableTypeEnum,
        Country: 'Country' as VariableTypeEnum,
        Currency: 'Currency' as VariableTypeEnum,
        Percentage: 'Percentage' as VariableTypeEnum
    };
    export type DisplayTypeEnum = 'Default' | 'Result' | 'Invisible';
    export const DisplayTypeEnum = {
        Default: 'Default' as DisplayTypeEnum,
        Result: 'Result' as DisplayTypeEnum,
        Invisible: 'Invisible' as DisplayTypeEnum
    };
}


