@if (!hideHeader) {
  <app-header class="lay-wrapper lay-wrapper--header mat-elevation-z8"></app-header>
}
<div class="app-content" role="main">
  @if (showFirstVisitPopup) {
    <app-first-visit-experience></app-first-visit-experience>
  }
  <div class="main-content-wrapper">
    <router-outlet [ngClass]="{ 'is-vis': isSidebarVisible }" style="height: 100%" [ngStyle]="{ width: sideBarWidth }" id="main-content"> </router-outlet>
  </div>
  @if ((isAuthenticated$ | async) && !healthcheckFail) {
    <app-sidebar
      class="side-bar-main-item"
      (sidebarToggle)="onSidebarToggle()"
      (selectItem)="onItemSelect($event)"
      [titles]="filteredDetails"
      [showSidebarOnStart]="false"
      [default]="false"
      role="navigation">
      <app-apps [class.full-height]="!hasDirects" [ngClass]="{ 'content-visible': selectedSidebarItems.includes(0) }" (toggle)="onItemToggle(0)"></app-apps>
      <app-directs
        [hidden]="!hasDirects"
        [isSidebarVisible]="isSidebarVisible"
        [isActive]="selectedSidebarItems.indexOf(1) > -1"
        [ngClass]="{ 'content-visible': selectedSidebarItems.includes(1) }"
        (toggle)="onItemToggle(1)"></app-directs>
    </app-sidebar>
  }
</div>
<app-footer></app-footer>
