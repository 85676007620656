<div class="chapter-overview-main">
  <div class="top-chapter-bar" [ngClass]="{ 'less-height-cp': collapsed }">
    @for (chapter of usedChapters; track chapter; let i = $index) {
      <div class="chapter-icon-main" (click)="chapterClick(chapter)" @animateChapter>
        @if (!collapsed) {
          <div
            @flyInFromTopNew
            class="chapter-icon top-chapter"
            [ngClass]="{
              'chapter-icon--last': i === usedChapters.length - 1,
              'chapter-icon--inbetween': i !== 0 && i !== usedChapters.length - 1,
              'chapter-icon--first': i === 0
            }">
            <span class="material-icons-outlined">{{ chapter.content['icon'] ? chapter.content['icon'] : 'view_in_ar' }}</span>
          </div>
        }
        <p [ngClass]="{ 'chapter-text--first': i === 0, 'chapter-text--last': i === usedChapters.length - 1 }" @flyInFromTopNew class="chapter-text">
          {{ chapter.content['text'] }}
        </p>
      </div>
      @if (i < usedChapters.length - 1) {
        <div class="chapter-line">
          <div class="flex-one"></div>
          <div class="chapter-line-item" [ngStyle]="{ background: collapsed ? 'transparent' : 'black' }"></div>
          <div class="flex-one"></div>
        </div>
      }
    }
  </div>
</div>
