<div>
  @if (!showDropdownFilter) {
    <div class="container">
      @if (!isInEditMode) {
        <button (click)="toggleEditMode()" class="more" [class.binary]="isBinary" [class.is-binary-filter-active]="isBinaryFilterActive">
          <ng-container *ngTemplateOutlet="informationTag"></ng-container>
        </button>
      }
      @if (isInEditMode) {
        <div>
          @switch (tag?.type) {
            @case (listViewFilterTagType.Input) {
              <coin-input
                #textInput
                (onClear)="onClearTextInput()"
                (onBlur)="submitTextInput()"
                (enterClicked)="submitTextInput()"
                [(ngModel)]="inputText"
                [placeholder]="tag?.title | translate"></coin-input>
            }
            @case (listViewFilterTagType.Date) {
              <coin-v2-date-input [placeholder]="'general.click-to-select' | translate" [formControl]="dateFormControl" />
            }
          }
        </div>
      }
    </div>
  }

  @if (showDropdownFilter) {
    <div class="container">
      <button class="more" [matMenuTriggerFor]="filterActions">
        <ng-container *ngTemplateOutlet="informationTag"></ng-container>
      </button>
      <mat-menu #filterActions="matMenu" class="filter-tag-options">
        @if (!!tag?.subFilters?.length) {
          @for (filter of tag.subFilters; track filter) {
            <button class="text" [class.selected]="isSelected(filter)" (click)="onFilterClicked(filter)" mat-menu-item>
              <div class="support-state-filter-item">
                <p>{{ filter?.title | translate }}</p>
                @if (!hideCount) {
                  <p>{{ filter?.count }}</p>
                }
              </div>
            </button>
          }
        }
      </mat-menu>
    </div>
  }
</div>

<ng-template #informationTag>
  <coin-v2-dual-pill
    [contentLeft]="tag?.title | translate"
    [textRight]="tagValue | translate"
    [icon]="tag?.icon"
    [svgIcon]="tag?.svgIcon"
    [themeColor]="tag?.color"></coin-v2-dual-pill>
</ng-template>
