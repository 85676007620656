import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { IncentiveAllocationEmployee } from '../../merit-shared/models/incentive-allocation-employee.model';
import { IncentiveAllocationOverview } from '../../merit-shared/models/incentive-budget-overview.model';
import { MeritAllocationImpersonatedEmployeeService } from '../../merit-shared/services/merit-allocation-impersonated-employee.service';
import { IncentiveUpdateAllocationValues } from '../models/incentive-update-allocation-values.model';

@Injectable({
  providedIn: 'root'
})
export class IncentiveAllocationService {
  constructor(
    private httpClient: HttpClient,
    private loadingService: LoadingService,
    private httpHelpersService: HttpHelpersService,
    private meritAllocationImpersonatedEmployeeService: MeritAllocationImpersonatedEmployeeService
  ) {}

  public getAllocationDirectsOverview(seasonId: string): Observable<IncentiveAllocationOverview> {
    this.loadingService.present();
    return this.httpClient
      .get<IncentiveAllocationOverview>(
        `${
          environment.api.baseUrl
        }/customer/api/v2/master/seasons/incentive/allocations/directs-overview?meritSeasonId=${seasonId}${this.meritAllocationImpersonatedEmployeeService.getEmployeeIdQuery()}`
      )
      .pipe(
        map(overview => this.mapOverview(overview)),
        catchError(() => of(null)),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public getAllocationTotalOverview(seasonId: string, parentOrgCode: string): Observable<IncentiveAllocationOverview> {
    this.loadingService.present();
    return this.httpClient
      .get<IncentiveAllocationOverview>(
        `${environment.api.baseUrl}/customer/api/v2/master/seasons/incentive/allocations/total-overview?meritSeasonId=${seasonId}&parentOrgCode=${encodeURIComponent(
          parentOrgCode
        )}${this.meritAllocationImpersonatedEmployeeService.getEmployeeIdQuery()}`
      )
      .pipe(
        map(overview => this.mapOverview(overview)),
        catchError(() => of(null)),
        finalize(() => this.loadingService.dismiss())
      );
  }

  public updateAllocationValues(body: IncentiveUpdateAllocationValues, allocationMemberId: string): Observable<IncentiveAllocationEmployee> {
    this.loadingService.present();
    return this.httpClient
      .patch<IncentiveAllocationEmployee>(`${environment.api.baseUrl}/customer/api/v2/master/seasons/incentive/allocations/${allocationMemberId}/values`, body)
      .pipe(
        map(employee => ({ ...employee, id: employee.allocationMemberId ?? employee.id })), // hack because backend cannot deliver right id
        this.httpHelpersService.handleError('Could not update allocation.'),
        finalize(() => this.loadingService.dismiss())
      );
  }

  private mapOverview(overview: IncentiveAllocationOverview): IncentiveAllocationOverview {
    return { ...overview, originalTiaSum: overview?.proratedTiaSum - overview?.proratedTiaIncrease };
  }
}
