@if (item) {
  <div style="padding: 20px 0px; margin-top: 30px">
    @if (!contentEdit) {
      <h3>{{ item.headline }}</h3>
    }
    @if (contentEdit) {
      <h3 [contentEditable]="contentEdit" [textContent]="item.headline" (input)="item.headline = $event.target.textContent"></h3>
    }
    @if (startanimation) {
      <div
        style="
          display: grid;
          margin-top: 30px;
          margin-bottom: 30px;
          padding: 50px;
          padding-bottom: 0px;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          column-gap: 15px;
          row-gap: 15px;
        ">
        @for (item of item.numbers; track item; let i = $index) {
          <div style="flex: 1" class="item-container">
            @if (contentEdit) {
              <div class="action-button-container">
                <coin-action-button (click)="removeNumber(i)" class="secondary" icon="close"></coin-action-button>
              </div>
            }
            @if (!contentEdit) {
              <p>{{ item.topText }}</p>
            }
            @if (contentEdit) {
              <p [contentEditable]="contentEdit" [textContent]="item.topText" (input)="item.topText = $event.target.textContent"></p>
            }
            @if (item.numbers.length === 1 && !contentEdit) {
              <h1>{{ countUpHolder[i][0][0] | numberFormatter }}</h1>
            }
            @if (item.numbers.length === 1 && contentEdit) {
              <h1 [contentEditable]="contentEdit" [textContent]="item.numbers[0]" (input)="updateItemNumber($event.target.textContent, item, 0)"></h1>
            }
            @if (item.numbers.length === 2) {
              <div class="double-number-input" style="display: flex; flex-wrap: wrap">
                @if (!contentEdit) {
                  <h1>{{ countUpHolder[i][0][0] | numberFormatter }}</h1>
                }
                @if (contentEdit) {
                  <h1 [contentEditable]="contentEdit" [textContent]="item.numbers[0]" (input)="updateItemNumber($event.target.textContent, item, 0)"></h1>
                }
                <h1 class="middle">to</h1>
                @if (!contentEdit) {
                  <h1>{{ countUpHolder[i][0][1] | numberFormatter }}</h1>
                }
                @if (contentEdit) {
                  <h1 [contentEditable]="contentEdit" [textContent]="item.numbers[1]" (input)="updateItemNumber($event.target.textContent, item, 1)"></h1>
                }
              </div>
            }
            @if (!contentEdit) {
              <p>{{ item.bottomText }}</p>
            }
            @if (contentEdit) {
              <p [contentEditable]="contentEdit" [textContent]="item.bottomText" (input)="item.bottomText = $event.target.textContent"></p>
            }
            @if (contentEdit) {
              <div>
                @if (contentEdit) {
                  <mat-form-field appearance="fill">
                    <mat-label>{{ 'cms.amount-numbers' | translate }}</mat-label>
                    <mat-select (selectionChange)="amountChange($event.value, i, item)" [(ngModel)]="numberLengths[i]">
                      @for (option of amountOptions; track option) {
                        <mat-option [value]="option">{{ option }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                }
                <!-- <gmm-select
        [model]="numberLengthes[i]"
        [label]="'Amount numbers'"
        [optionList]="amountOptions"
        [disabled]="!contentEdit"
        [required]="true"
        (modelChange)="amountChange($event, i, item)"
        [name]="'amountNumbers'"></gmm-select> -->
              </div>
            }
          </div>
        }

        @if (contentEdit) {
          <div class="action-button-container add">
            <coin-action-button (click)="addNumber()" icon="add" class="primary"></coin-action-button>
          </div>
        }
      </div>
    }
  </div>
}
