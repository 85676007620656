<div class="container">
  <div class="close-button" (click)="closeVisit()">
    <mat-icon>close</mat-icon>
  </div>
  <div class="content">
    <div class="header" cdkDrag cdkDragRootElement=".container" [cdkDragFreeDragPosition]="offset" cdkDragHandle>
      <h3 #testITEM class="fv-header-text">{{ 'guided-tour.g2m.top-headline' | translate }}</h3>
      <coin-simple-lang-switch [excludedLangs]="['fr', 'es']"></coin-simple-lang-switch>
    </div>
    @if (specialImageData) {
      <div class="special-image" [ngStyle]="{ 'background-image': 'url(' + specialImageData + ')' }"></div>
    }
    <mat-tab-group class="invisible-tabs" [selectedIndex]="selectedTab" (selectedIndexChange)="tabChange($event)">
      <mat-tab>
        <ng-template matTabContent>
          <coin-c-g2m-intro class="max-height-area" [maxHeight]="tourMaxHeight"></coin-c-g2m-intro>
        </ng-template>
      </mat-tab>
      @for (tourItem of tourLength; track tourItem) {
        <mat-tab>
          <ng-template matTabContent>
            <app-fv-left
              [image]="imageLink"
              [text]="text"
              [needsColumn]="needsColumn"
              [maxHeight]="tourMaxHeight"
              [maxWidth]="tourMaxWidth"
              [imageMaxWidth]="'450px'"></app-fv-left>
          </ng-template>
        </mat-tab>
      }
    </mat-tab-group>
    <div class="fv-buttons">
      @if (selectedTab > 0) {
        <coin-action-button class="secondary fv-btn fv-btn--left" (click)="back()" arrowPos="left">Back</coin-action-button>
      }
      @if (selectedTab < 6) {
        <coin-action-button class="primary fv-btn fv-btn--right" (click)="next()">{{ selectedTab > 0 ? 'Next' : 'Start Guided Tour' }}</coin-action-button>
      }
      @if (selectedTab === 0) {
        <coin-action-button class="secondary fv-btn fv-btn--right" (click)="openGrip2MercerPage()" icon="link">{{
          'guided-tour.g2m.learn-more-g2m' | translate
        }}</coin-action-button>
      }
      @if (selectedTab === 6) {
        <coin-action-button class="primary fv-btn fv-btn--right" (click)="completeTour()">{{ 'End Guided Tour' }}</coin-action-button>
      }
    </div>
    <div class="fv-fake-item">
      <!-- <app-topic-item *ngIf="showFakeItem" @shrinkExpand class="topic-item-container fake-child"
        [item]="fakeItem"></app-topic-item> -->
    </div>
  </div>
</div>
@if (nso) {
  <div class="new-special-overlay" [ngStyle]="{ 'border-top-width': nso.bt, 'border-bottom-width': nso.bb, 'border-left-width': nso.bl, 'border-right-width': nso.br }"></div>
}
@if (nso2) {
  <div class="inverted-overlay" [ngStyle]="{ 'padding-top': nso2.bt, 'padding-bottom': nso2.bb, 'padding-left': nso2.bl, 'padding-right': nso2.br }">
    <div></div>
  </div>
}
