<span>
  @if (!usages) {
    <p>
      {{ 'cms.click-calculate-usages-info' | translate }}
      <mat-icon class="material-icons-outlined" [matTooltip]="'cms.calculate-image-usages-tooltip' | translate" matTooltipPosition="above">info_outlined</mat-icon>
    </p>
  }
  @if (usages) {
    <p>{{ 'cms.selected-image-used-in-header' | translate }}</p>
  }
</span>
@if (usages) {
  <mat-accordion>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [disabled]="!usages?.emails?.length">
      <mat-expansion-panel-header>
        <mat-panel-title> Emails </mat-panel-title>
        <mat-panel-description>
          {{ usages?.emails?.length ? getCountTranslation + usages?.emails?.length + ' Emails' : getNoUsageFoundTranslation }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      @if (usages?.emails?.length) {
        <mat-list>
          @for (emailKey of usages.emails; track emailKey) {
            <mat-list-item>
              <a [href]="getEmailHref(emailKey)" target="_blank"> &#x2022; {{ getEmailDisplayName(emailKey) }}</a>
            </mat-list-item>
          }
        </mat-list>
      }
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [disabled]="!usages?.news?.length">
      <mat-expansion-panel-header>
        <mat-panel-title> News </mat-panel-title>
        <mat-panel-description>
          {{ usages?.news?.length ? getCountTranslation + usages?.news?.length + ' News' : getNoUsageFoundTranslation }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      @if (usages?.news?.length) {
        <mat-list class="scroll-list">
          @for (newsKey of usages.news; track newsKey) {
            <mat-list-item>
              <a [href]="getNewsHref(newsKey)" target="_blank"> &#x2022; {{ getNewsDisplayName(newsKey) }}</a>
            </mat-list-item>
          }
        </mat-list>
      }
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" [disabled]="!isImageInContentPageUsed">
      <mat-expansion-panel-header>
        <mat-panel-title> Content Pages </mat-panel-title>
        <mat-panel-description>
          {{ contentPageCount ? getCountTranslation + contentPageCount + ' Content Pages' : getNoUsageFoundTranslation }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      @if (!!contentPageCount) {
        <mat-list class="scroll-list">
          @for (item of usages.contentPages | keyvalue; track item) {
            <mat-list-item>
              <span matListItemTitle>{{ getContentPageKey(item.key) }}</span>
            </mat-list-item>
            @for (contentPage of item.value; track contentPage) {
              <mat-list>
                <mat-list-item>
                  <a [href]="getContentPageHref(contentPage)" target="_blank"> &#x2022; {{ contentPage }}</a>
                </mat-list-item>
              </mat-list>
            }
          }
        </mat-list>
      }
    </mat-expansion-panel>
  </mat-accordion>
}
