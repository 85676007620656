/* eslint-disable no-case-declarations */
import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '@coin/shared/data-access';
import { Employee, EmptyGuid, PaginatedResult } from '@coin/shared/util-models';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { produce } from 'immer';
import { Observable, of } from 'rxjs';
import { filter, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { FilterSortState } from '@coin/customer/shared/filter-sort-bar-data-access';
import { OrgTreeSortType } from '../../../shared/enums/org-tree-sorting.enum';
import { IncentiveAllocationEmployee } from '../../merit-shared/models/incentive-allocation-employee.model';
import { MeritAllocationOrganisationSnapshot } from '../../merit-shared/models/merit-allocation-organisation-snapshot.model';
import { EmployeeWithCompensationInfo } from '../../merit-shared/models/merit-budget-direct-with-compensation.model';
import { AllocationOrgDetailsMappingService } from '../../merit-shared/services/allocation-org-details-mapping.service';
import { AllocationUserOrgCodeService } from '../../merit-shared/services/allocation-user-org-code.service';
import { MeritAllocationImpersonatedEmployeeService } from '../../merit-shared/services/merit-allocation-impersonated-employee.service';
import { MeritAllocationOrganisationsService } from '../../merit-shared/services/merit-allocation-organisations.service';
import {
  LazyLoadAllocationMembers,
  LoadAllocationMemberChildren,
  LoadAllocationMemberChildrenSuccess,
  LoadAllocationMembers,
  LoadUserHeadOrganisations,
  MeritAllocationOpenEmployeeInView,
  ReloadAllocationMemberChild,
  ResetChildrenVisibility,
  ResetMeritBudgetAllocationTotalState,
  SetCurrentLevel,
  SetSelectedOrganisation,
  UpdateBreadcrumbItems,
  UpdateCurrentLevel,
  UpdateNode,
  UpdateNodeCompensationComponents,
  UpdateSearchedNode,
  UpdateSortingState
} from './merit-budget-allocation-total.actions';
import { MeritBudgetAllocationState } from './merit-budget-allocation.state';
import { ReloadPromotions } from './merit-budget-promotion.actions';

const MAX_DEPTH_LEVEL = 10;
const DEFAULT_ID = EmptyGuid;
const INITIAL_PAGE = 1;

export interface EmployeeAllocation extends Partial<EmployeeWithCompensationInfo>, Partial<IncentiveAllocationEmployee> {}

export interface AllocationOrgDetailsNode extends EmployeeAllocation {
  parentOrgCode: string;
  childNodes: string[];
  isHead: boolean;
}

interface SearchedNode {
  node: Employee;
  isActive: boolean;
}

export interface BreadcrumbItem extends EmployeeWithCompensationInfo {
  childrenActive: boolean;
  disabledDepth?: boolean;
  isRoot: boolean;
}

export interface MeritBudgetAllocationTotalStateModel {
  nodes: AllocationOrgDetailsNode[];
  breadcrumbItems: BreadcrumbItem[];
  areEmployeesLoading: boolean;
  searchedNode: SearchedNode;
  sortingType: OrgTreeSortType;
  currentLevel: number;
  userOrganisations: MeritAllocationOrganisationSnapshot[];
  selectedOrganisation: MeritAllocationOrganisationSnapshot;
  page: number;
  maxPage: number;
}

@State<MeritBudgetAllocationTotalStateModel>({
  name: 'MeritBudgetAllocationTotalState',
  defaults: {
    nodes: [],
    breadcrumbItems: [],
    areEmployeesLoading: false,
    sortingType: OrgTreeSortType.Default,
    searchedNode: { node: null, isActive: false },
    currentLevel: 1,
    userOrganisations: [],
    selectedOrganisation: null,
    page: INITIAL_PAGE,
    maxPage: INITIAL_PAGE
  }
})
@Injectable()
export class MeritBudgetAllocationTotalState {
  constructor(
    private meritAllocationOrganisationsService: MeritAllocationOrganisationsService,
    private allocationOrgDetailsMappingService: AllocationOrgDetailsMappingService,
    private store: Store,
    private loadingService: LoadingService,
    private allocationUserOrgCodeService: AllocationUserOrgCodeService,
    private meritAllocationImpersonatedEmployeeService: MeritAllocationImpersonatedEmployeeService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private destroyRef: DestroyRef
  ) {}

  @Selector()
  static breadcrumbItems(state: MeritBudgetAllocationTotalStateModel): BreadcrumbItem[] {
    return state.breadcrumbItems;
  }

  @Selector()
  static areEmployeesLoading(state: MeritBudgetAllocationTotalStateModel): boolean {
    return state.areEmployeesLoading;
  }

  @Selector()
  static sortingType(state: MeritBudgetAllocationTotalStateModel): OrgTreeSortType {
    return state.sortingType;
  }

  @Selector()
  static currentLevel(state: MeritBudgetAllocationTotalStateModel): number {
    return state.currentLevel;
  }

  @Selector()
  static userOrganisations(state: MeritBudgetAllocationTotalStateModel): MeritAllocationOrganisationSnapshot[] {
    return state.userOrganisations;
  }

  @Selector()
  static selectedOrganisation(state: MeritBudgetAllocationTotalStateModel): MeritAllocationOrganisationSnapshot {
    return state.selectedOrganisation;
  }

  @Selector()
  static selectedOrganisationOrgCode(state: MeritBudgetAllocationTotalStateModel): string {
    return state.selectedOrganisation?.orgCode;
  }

  @Selector()
  static isHeadOfHeads(state: MeritBudgetAllocationTotalStateModel): boolean {
    return state.userOrganisations?.some(org => org.isParentOrganisation);
  }

  @Selector()
  static getNodesByLastBCItem(state: MeritBudgetAllocationTotalStateModel): AllocationOrgDetailsNode[] {
    const id = state.breadcrumbItems[state.breadcrumbItems.length - 1]?.id;
    const parent = state.nodes?.find(node => node.displayId === id);
    return parent?.childNodes?.map(id => state.nodes.find(node => node.displayId === id));
  }

  static getNodesByParentId(id: string): (state: MeritBudgetAllocationTotalStateModel) => AllocationOrgDetailsNode[] {
    return createSelector([MeritBudgetAllocationTotalState], (state: MeritBudgetAllocationTotalStateModel) => {
      const parent = state.nodes?.find(node => node.displayId === id);
      return parent?.childNodes?.map(id => state.nodes.find(node => node.displayId === id));
    });
  }

  static hasSubHeadNodes(id: string): (state: MeritBudgetAllocationTotalStateModel) => boolean {
    return createSelector([MeritBudgetAllocationTotalState], (state: MeritBudgetAllocationTotalStateModel) => {
      const parent = state.nodes?.find(node => node.displayId === id);
      return parent?.childNodes?.map(id => state.nodes.find(node => node.displayId === id)).some(node => node.isHead);
    });
  }

  static node(id: string): (state: MeritBudgetAllocationTotalStateModel) => AllocationOrgDetailsNode {
    return createSelector([MeritBudgetAllocationTotalState], (state: MeritBudgetAllocationTotalStateModel) => {
      return state.nodes.find(node => node.displayId === id);
    });
  }

  static nodeByHead(headId: string, orgCode: string): (state: MeritBudgetAllocationTotalStateModel) => AllocationOrgDetailsNode {
    return createSelector([MeritBudgetAllocationTotalState], (state: MeritBudgetAllocationTotalStateModel) => {
      return state.nodes.find(node => node.headId === headId && node.orgCode === orgCode);
    });
  }

  static employeeNode(employeeId: string): (state: MeritBudgetAllocationTotalStateModel) => AllocationOrgDetailsNode {
    return createSelector([MeritBudgetAllocationTotalState], (state: MeritBudgetAllocationTotalStateModel) => {
      const isLoading = !state.nodes.length || (state.nodes.length === 1 && state.nodes[0].id === state.selectedOrganisation.headId);
      if (isLoading) {
        return undefined;
      }
      return state.nodes?.find(node => node.employeeId === employeeId) ?? null;
    });
  }

  static rootNode(): (state: MeritBudgetAllocationTotalStateModel) => AllocationOrgDetailsNode {
    return createSelector([MeritBudgetAllocationTotalState], (state: MeritBudgetAllocationTotalStateModel) => {
      return state.nodes.find(node => node.parentOrgCode === 'root');
    });
  }

  @Action(UpdateSortingState)
  updateSortingType(ctx: StateContext<MeritBudgetAllocationTotalStateModel>, { payload }: UpdateSortingState): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.sortingType = payload;
      })
    );
  }

  @Action(LoadAllocationMembers)
  loadAllocationMembers(ctx: StateContext<MeritBudgetAllocationTotalStateModel>, { payload }: LoadAllocationMembers): Observable<string | void> {
    let node: Partial<AllocationOrgDetailsNode>;
    if (payload.loadRoot) {
      node = { id: 'root', displayId: 'root', paOrgCode: 'root' };
    } else {
      const id = ctx.getState().breadcrumbItems[ctx.getState().breadcrumbItems.length - 1]?.id;
      node = ctx.getState().nodes?.find(node => node.displayId === id);
    }

    if (ctx.getState().nodes?.some(node => node.displayId === 'root')) {
      return of('');
    }

    ctx.setState(
      produce(ctx.getState(), state => {
        this.updateNodes(state.nodes, { ...node });
        const index = state.nodes.findIndex(e => e.displayId === node.displayId);
        state.nodes[index].childNodes = [];
      })
    );

    return ctx.dispatch(new LoadAllocationMemberChildren({ node: node, reload: payload.reload, resetSearchNode: true, isFirstPageReload: true }));
  }

  @Action(LazyLoadAllocationMembers)
  lazyLoadAllocationMembers(ctx: StateContext<MeritBudgetAllocationTotalStateModel>): Observable<void> {
    const id = ctx.getState().breadcrumbItems[ctx.getState().breadcrumbItems.length - 1]?.id;
    const node = ctx.getState().nodes?.find(node => node.displayId === id);

    return ctx.dispatch(new LoadAllocationMemberChildren({ node: node, reload: true, resetSearchNode: true, isFirstPageReload: false }));
  }

  @Action(LoadAllocationMemberChildren)
  loadAllocationMemberChildren(
    ctx: StateContext<MeritBudgetAllocationTotalStateModel>,
    { payload }: LoadAllocationMemberChildren
  ): Observable<string | void | PaginatedResult<EmployeeWithCompensationInfo | IncentiveAllocationEmployee>> {
    if (!payload.reload && !payload.disableNavigation) {
      const childNodes = this.store.selectSnapshot(MeritBudgetAllocationTotalState.getNodesByParentId(payload?.node?.displayId));
      if (childNodes?.length) {
        // do not fire req if childNodes already existent, but update breadcrumb
        return !payload.isBudgetView || childNodes.some(childNode => childNode?.isHead) ? ctx.dispatch(new UpdateBreadcrumbItems({ id: payload.node.displayId })) : of(null);
      }
    }

    if (payload.resetSearchNode && !payload.disableNavigation) {
      ctx.dispatch(new UpdateSearchedNode({ node: null, isActive: false }));
    }

    const orgCode = payload?.node?.headId || payload?.node?.isHead ? payload.node?.orgCode : payload.node?.paOrgCode;
    const seasonId = this.store.selectSnapshot(MeritBudgetAllocationState.season).id;
    const filterText = !payload.disableNavigation ? this.store.selectSnapshot(FilterSortState.getSortAndFilterText) : '';
    const page = payload.isFirstPageReload ? INITIAL_PAGE : ctx.getState().page;

    if (page > ctx.getState().maxPage && !payload.isFirstPageReload) {
      return of('');
    }

    if (!orgCode || !seasonId) {
      return of(null);
    }

    return this.allocationOrgDetailsMappingService.service.getTotalAllocations(page, 25, seasonId, `&parentOrgCode=${encodeURIComponent(orgCode)}${filterText ?? ''}`).pipe(
      filter(data => !!data?.content?.length),
      mergeMap((data: PaginatedResult<EmployeeWithCompensationInfo | IncentiveAllocationEmployee>) => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.page = payload.isFirstPageReload ? INITIAL_PAGE + 1 : state.page + 1;
            state.maxPage = payload.isFirstPageReload ? data.pageCount : state.maxPage;
          })
        );
        const members = data.content?.filter(node => payload.node.headId !== node.id);

        return ctx.dispatch(
          new LoadAllocationMemberChildrenSuccess({
            children: members,
            id: payload.node.displayId,
            reload: payload.reload,
            disableNavigation: payload.disableNavigation,
            isBudgetView: payload.isBudgetView
          })
        );
      }),
      finalize(() => ctx.patchState({ areEmployeesLoading: false }))
    );
  }

  @Action(ReloadAllocationMemberChild)
  reloadAllocationMemberChild(ctx: StateContext<MeritBudgetAllocationTotalStateModel>, { id }: ReloadAllocationMemberChild): Observable<unknown> {
    const seasonId = this.store.selectSnapshot(MeritBudgetAllocationState.season).id;

    return this.allocationOrgDetailsMappingService.service.getAllocationMember(seasonId, id).pipe(
      map(node =>
        ctx.setState(
          produce(ctx.getState(), state => {
            if (node) {
              const index = state.nodes.findIndex(stateNode => stateNode.displayId === node?.displayId);
              if (index > -1) {
                state.nodes[index] = { ...state.nodes[index], ...node };
              } else {
                state.nodes.push(node as AllocationOrgDetailsNode);
              }
            }
          })
        )
      )
    );
  }

  @Action(LoadAllocationMemberChildrenSuccess)
  loadAllocationMemberChildrenSuccess(ctx: StateContext<MeritBudgetAllocationTotalStateModel>, { payload }: LoadAllocationMemberChildrenSuccess): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        if (!payload.children) {
          return;
        }

        for (const child of payload.children) {
          this.updateNodes(state.nodes, { ...child, ...{ parentId: payload.id } });
        }

        const nodeIndex = state.nodes.findIndex(node => node.displayId === payload.id);

        const nodes = state.nodes[nodeIndex].childNodes || [];
        state.nodes[nodeIndex].childNodes = [...new Set([...nodes, ...payload.children.map(child => child?.displayId)])];
      })
    );

    if (payload.disableNavigation || (payload.isBudgetView && !payload.children.some(childNode => childNode?.isHead))) {
      return;
    }

    ctx.dispatch(new UpdateBreadcrumbItems({ id: payload.id }));
  }

  @Action(MeritAllocationOpenEmployeeInView)
  async openEmployeeInView(ctx: StateContext<MeritBudgetAllocationTotalStateModel>, { payload }: MeritAllocationOpenEmployeeInView): Promise<void> {
    try {
      this.loadingService.present();

      let parents: EmployeeAllocation[] = [];

      await this.collectAllParents(ctx.getState().selectedOrganisation?.orgCode, payload.node, parents, true, payload.fullTree);

      if (payload.node?.id === parents[0]?.headId && parents?.length > 1 && payload?.fullTree) {
        parents.shift();
      }

      ctx.dispatch(new ResetChildrenVisibility());
      const loadActions: UpdateBreadcrumbItems[] = [];

      const newHeadInView: EmployeeAllocation = parents[0];
      parents = parents.filter(parent => parent.paOrgCode !== 'root')?.reverse();

      parents.forEach(parent => {
        ctx.setState(
          produce(ctx.getState(), state => {
            this.updateNodes(state.nodes, { ...parent });
          })
        );
        loadActions.push(new UpdateBreadcrumbItems({ id: parent.displayId }));
      });

      for (const [index, action] of loadActions.entries()) {
        setTimeout(async () => {
          ctx.dispatch(action);
        }, index * 200);
      }

      setTimeout(() => {
        ctx
          .dispatch(new LoadAllocationMemberChildren({ node: newHeadInView, reload: true, resetSearchNode: false, isFirstPageReload: true }))
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => {
            ctx.dispatch(new UpdateSearchedNode({ node: payload.node, isActive: true }));
          });
      }, 200);
    } catch (e) {
      throw Error(e);
    } finally {
      this.loadingService.dismiss();
    }
  }

  @Action(UpdateBreadcrumbItems)
  updateBreadcrumbItems(ctx: StateContext<MeritBudgetAllocationTotalStateModel>, { payload }: UpdateBreadcrumbItems): void {
    // case for reloading root hubs
    if (payload?.id === null) {
      payload.id = 'root';
    }

    ctx.setState(
      produce(ctx.getState(), state => {
        if (!payload) {
          // reset items
          state.breadcrumbItems.splice(1, state.breadcrumbItems.length);
          return;
        }

        if (state.breadcrumbItems.some(item => item.id === payload.id)) {
          // update item

          let pos: number;
          for (let i = 0; i < state.breadcrumbItems.length; i++) {
            if (state.breadcrumbItems[i].id === payload.id) {
              pos = i;
              state.breadcrumbItems[i].version += 1;
            }
          }
          if (payload.id !== 'root') {
            state.breadcrumbItems.splice(pos + 1, state.breadcrumbItems.length); // delete unnecessary breadcrumb items
          }
        } else if (state.breadcrumbItems.length === MAX_DEPTH_LEVEL) {
          state.breadcrumbItems.push({ id: payload.id, childrenActive: true, disabledDepth: true, isRoot: payload.id === 'root', version: 0 });
        } else {
          state.breadcrumbItems.push({ id: payload.id, childrenActive: true, isRoot: payload.id === 'root', version: 0 });
        }
      })
    );
  }

  @Action(ResetChildrenVisibility)
  resetChildrenVisibility(ctx: StateContext<MeritBudgetAllocationTotalStateModel>): Observable<void> {
    return ctx.dispatch(new UpdateBreadcrumbItems());
  }

  @Action(SetSelectedOrganisation)
  setSelectedOrganisation(ctx: StateContext<MeritBudgetAllocationTotalStateModel>, { payload }: SetSelectedOrganisation): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.selectedOrganisation = payload;
      })
    );
  }

  @Action(UpdateSearchedNode)
  updateSearchedNode(ctx: StateContext<MeritBudgetAllocationTotalStateModel>, { payload }: UpdateSearchedNode): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.searchedNode.node = payload.node;
        state.searchedNode.isActive = payload.isActive;
      })
    );
  }

  @Action(SetCurrentLevel)
  setCurrentLevel(ctx: StateContext<MeritBudgetAllocationTotalStateModel>, { payload }: SetCurrentLevel): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.currentLevel = payload.level;
      })
    );
  }

  @Action(UpdateCurrentLevel)
  updateCurrentLevel(ctx: StateContext<MeritBudgetAllocationTotalStateModel>, { payload }: UpdateCurrentLevel): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.currentLevel += payload.levelDelta;
      })
    );
  }

  @Action(UpdateNode)
  updateNode(ctx: StateContext<MeritBudgetAllocationTotalStateModel>, { payload }: UpdateNode): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        this.updateNodes(state.nodes, payload);
      })
    );
  }

  @Action(LoadUserHeadOrganisations)
  loadUserHeadOrganisations(ctx: StateContext<MeritBudgetAllocationTotalStateModel>): Observable<[MeritAllocationOrganisationSnapshot[], string]> {
    const seasonId = this.store.selectSnapshot(MeritBudgetAllocationState.season)?.id;

    ctx.patchState({ areEmployeesLoading: true });
    return this.meritAllocationImpersonatedEmployeeService.getGid().pipe(
      switchMap(gid => this.meritAllocationOrganisationsService.getOrganisations(seasonId, `&query.headGid=${encodeURIComponent(gid)}`)),
      switchMap(orgs => this.allocationUserOrgCodeService.getUserOrgCode().pipe(map(orgCode => [orgs, orgCode] as [MeritAllocationOrganisationSnapshot[], string]))),
      tap(([orgs, userOrgCode]) => {
        ctx.setState(
          produce(ctx.getState(), state => {
            const headOfHeadsOrgs = orgs.filter(org => org.isParentOrganisation);
            if (headOfHeadsOrgs?.length) {
              const organizationInView = headOfHeadsOrgs.find(org => org.orgCode === userOrgCode) ?? headOfHeadsOrgs[0];
              state.userOrganisations = orgs;
              state.selectedOrganisation = organizationInView;
              state.nodes = [];
              state.breadcrumbItems = [];
              state.searchedNode = { node: null, isActive: false };
            } else {
              state.userOrganisations = orgs;
            }
          })
        );
      }),
      tap(async () => {
        if (MeritBudgetAllocationTotalState.isHeadOfHeads(ctx.getState())) {
          const selectedOrg = ctx.getState().selectedOrganisation;
          let am: EmployeeWithCompensationInfo = await this.allocationOrgDetailsMappingService.service
            .getAllocationMember(seasonId, selectedOrg.headId, selectedOrg.orgCode)
            .toPromise();

          // Ensure the OrgCode and ParentOrgCode is taken from the AllocationManager instead of from the Employee
          am = { ...am, ...am.employeeSnapshot, orgCode: am.orgCode, paOrgCode: am.paOrgCode ?? am.orgCode };

          ctx.dispatch(new MeritAllocationOpenEmployeeInView({ node: am, fullTree: false }));
          if (this.router.url.includes('/promotions')) {
            ctx.dispatch(new ReloadPromotions());
          }
          ctx.dispatch(new SetCurrentLevel({ level: 1 }));
        }
      })
    );
  }

  @Action(UpdateNodeCompensationComponents)
  updateNodeCompensationComponents(ctx: StateContext<MeritBudgetAllocationTotalStateModel>, { payload }: UpdateNodeCompensationComponents): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        const foundIndex = state.nodes.findIndex(node => node.employeeId === payload.employeeId);

        if (foundIndex >= 0) {
          const node = state.nodes[foundIndex];
          node.compensationComponents = payload.compensationComponents ?? node?.compensationComponents;
          node.isDone = payload.isDone ?? node?.isDone;
          node.promotion = payload.promotion ?? node?.promotion;
        }
      })
    );
  }

  updateNodes(nodes: Partial<AllocationOrgDetailsNode>[], updatedNode: Partial<AllocationOrgDetailsNode>): void {
    // check if node already exists
    if (nodes.find(node => node.displayId === updatedNode.displayId && node.paOrgCode === updatedNode.paOrgCode)) {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].displayId === updatedNode.displayId) {
          nodes[i] = { ...nodes[i], ...updatedNode };
        }
      }
    } else {
      nodes.push(updatedNode);
    }
  }

  async collectAllParents(selectedOrgCode: string, employee: Employee, parents: EmployeeAllocation[], isInital: boolean, isFullTree: boolean): Promise<void> {
    const seasonId = this.store.selectSnapshot(MeritBudgetAllocationState.season).id;
    const orgCode = employee?.headId || employee?.isHead ? employee?.orgCode : employee?.paOrgCode;

    let parentHead;

    if (orgCode) {
      if (isInital) {
        const orgs = await this.meritAllocationOrganisationsService.getOrganisations(seasonId, `&query.orgCode=${encodeURIComponent(orgCode)}`).toPromise();
        parentHead = orgs?.[0];
      } else {
        parentHead = await this.meritAllocationOrganisationsService.getParentOrganisation(seasonId, orgCode).toPromise();
      }
      if (parentHead.headId && parentHead.headId !== DEFAULT_ID) {
        parentHead.placeOfAction = parentHead.headPlaceOfAction; // hack
        parentHead.paOrgCode = parentHead.headPAOrgCode; // hack
      }

      const headAm: EmployeeWithCompensationInfo = await this.allocationOrgDetailsMappingService.service
        .getAllocationMember(seasonId, parentHead?.headId, parentHead?.orgCode)
        .toPromise();
      if (headAm) {
        const { employeeSnapshot } = headAm;
        headAm.firstname = headAm.firstname || employeeSnapshot?.firstname;
        headAm.lastname = headAm.lastname || employeeSnapshot?.lastname;
        headAm.placeOfAction = headAm.placeOfAction || employeeSnapshot?.placeOfAction;
        headAm.gid = headAm.gid || employeeSnapshot?.gid;
        parents.push(headAm);
      } else {
        parents.push(parentHead);
      }

      if (parentHead.orgCode !== selectedOrgCode && isFullTree) {
        await this.collectAllParents(selectedOrgCode, parentHead, parents, false, true);
      }
    }
  }

  @Action(ResetMeritBudgetAllocationTotalState)
  resetState(ctx: StateContext<MeritBudgetAllocationTotalStateModel>): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.nodes = [];
        state.breadcrumbItems = [];
        state.sortingType = OrgTreeSortType.Default;
      })
    );
  }
}
