/**
 * IncentivePartnerApp
 * API for incentive HR support app
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomHttpParameterCodec } from '../encoder';

// @ts-ignore
import { IncentiveEmployeeSnapshot } from '../model/incentive-employee-snapshot.model';
// @ts-ignore
import { IncentiveSeason } from '../model/incentive-season.model';
// @ts-ignore
import { SeasonPage } from '../model/season-page.model';
// @ts-ignore
import { UpdateIncentiveSnapshot } from '../model/update-incentive-snapshot.model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

export interface GetSeasonsIncentivePartnerV1RequestParams {
  pagingPage?: number;
  pagingSize?: number;
  sortingProperty?: string;
  sortingOrderBy?: string;
}

export interface GetSeasonsIncentivePartnerV1ByIdRequestParams {
  id: string;
}

export interface PatchSeasonsIncentiveSeasonIdPartnerV1RecordsRecordIdEmployeeSnapshotsSnapshotIdRequestParams {
  seasonId: string;
  recordId: string;
  snapshotId: string;
  updateIncentiveSnapshot?: UpdateIncentiveSnapshot;
}

@Injectable({
  providedIn: 'root'
})
export class IncentivePartnerService {
  protected basePath = 'http://localhost:3000';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * GET incentive seasons
   * Get incentive seasons
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSeasonsIncentivePartnerV1(
    requestParameters: GetSeasonsIncentivePartnerV1RequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<SeasonPage>;
  public getSeasonsIncentivePartnerV1(
    requestParameters: GetSeasonsIncentivePartnerV1RequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<SeasonPage>>;
  public getSeasonsIncentivePartnerV1(
    requestParameters: GetSeasonsIncentivePartnerV1RequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<SeasonPage>>;
  public getSeasonsIncentivePartnerV1(
    requestParameters: GetSeasonsIncentivePartnerV1RequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    const { pagingPage } = requestParameters;
    const { pagingSize } = requestParameters;
    const { sortingProperty } = requestParameters;
    const { sortingOrderBy } = requestParameters;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (pagingPage !== undefined && pagingPage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingPage, 'paging.Page');
    }
    if (pagingSize !== undefined && pagingSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingSize, 'paging.Size');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'sorting.Property');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'sorting.OrderBy');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/partner/v1`;
    return this.httpClient.request<SeasonPage>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * GET incentive seasons
   * Get incentive season by Id
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSeasonsIncentivePartnerV1ById(
    requestParameters: GetSeasonsIncentivePartnerV1ByIdRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<IncentiveSeason>;
  public getSeasonsIncentivePartnerV1ById(
    requestParameters: GetSeasonsIncentivePartnerV1ByIdRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<IncentiveSeason>>;
  public getSeasonsIncentivePartnerV1ById(
    requestParameters: GetSeasonsIncentivePartnerV1ByIdRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<IncentiveSeason>>;
  public getSeasonsIncentivePartnerV1ById(
    requestParameters: GetSeasonsIncentivePartnerV1ByIdRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    const { id } = requestParameters;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getSeasonsIncentivePartnerV1ById.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/partner/v1/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<IncentiveSeason>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Update Incentive Employee Snapshot
   * partially updates an incentive employee snapshot
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchSeasonsIncentiveSeasonIdPartnerV1RecordsRecordIdEmployeeSnapshotsSnapshotId(
    requestParameters: PatchSeasonsIncentiveSeasonIdPartnerV1RecordsRecordIdEmployeeSnapshotsSnapshotIdRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<IncentiveEmployeeSnapshot>;
  public patchSeasonsIncentiveSeasonIdPartnerV1RecordsRecordIdEmployeeSnapshotsSnapshotId(
    requestParameters: PatchSeasonsIncentiveSeasonIdPartnerV1RecordsRecordIdEmployeeSnapshotsSnapshotIdRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<IncentiveEmployeeSnapshot>>;
  public patchSeasonsIncentiveSeasonIdPartnerV1RecordsRecordIdEmployeeSnapshotsSnapshotId(
    requestParameters: PatchSeasonsIncentiveSeasonIdPartnerV1RecordsRecordIdEmployeeSnapshotsSnapshotIdRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<IncentiveEmployeeSnapshot>>;
  public patchSeasonsIncentiveSeasonIdPartnerV1RecordsRecordIdEmployeeSnapshotsSnapshotId(
    requestParameters: PatchSeasonsIncentiveSeasonIdPartnerV1RecordsRecordIdEmployeeSnapshotsSnapshotIdRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    const { seasonId } = requestParameters;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling patchSeasonsIncentiveSeasonIdPartnerV1RecordsRecordIdEmployeeSnapshotsSnapshotId.');
    }
    const { recordId } = requestParameters;
    if (recordId === null || recordId === undefined) {
      throw new Error('Required parameter recordId was null or undefined when calling patchSeasonsIncentiveSeasonIdPartnerV1RecordsRecordIdEmployeeSnapshotsSnapshotId.');
    }
    const { snapshotId } = requestParameters;
    if (snapshotId === null || snapshotId === undefined) {
      throw new Error('Required parameter snapshotId was null or undefined when calling patchSeasonsIncentiveSeasonIdPartnerV1RecordsRecordIdEmployeeSnapshotsSnapshotId.');
    }
    const { updateIncentiveSnapshot } = requestParameters;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/partner/v1/records/${this.configuration.encodeParam({ name: 'recordId', value: recordId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/employee-snapshots/${this.configuration.encodeParam({ name: 'snapshotId', value: snapshotId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<IncentiveEmployeeSnapshot>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateIncentiveSnapshot,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
