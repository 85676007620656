import { IndividualMultiplierState, MeritEquityPlan, MeritEquityPlanDetails, MeritPlan, MeritPromotionState } from '@coin/customer/merit-incentive/util';
import { SeasonTypeEnum } from '@coin/shared/util-enums';
import { Employee, ListViewTagFilterParameter, MeritAllocationSeason } from '@coin/shared/util-models';
import { MeritSupportSortingParameter } from '../enums/merit-support-sorting-parameter.enum';

export class LoadSeasons {
  static readonly type = '[MeritSupport] Load Seasons';
}

export class LoadEmployees {
  static readonly type = '[MeritSupport] Load Employees';
}

export class LoadEmployeeByGid {
  static readonly type = '[MeritSupport] Load Employee By Gid';
  constructor(public readonly gid: string) {}
}

export class ExportEmployees {
  static readonly type = '[MeritSupport] Export Employees';
}

export class LazyLoadEmployees {
  static readonly type = '[MeritSupport] Lazy Load Employees';
}

export class SetEmployeeBySearch {
  static readonly type = '[MeritSupport] Set Employee By Search';
  constructor(public readonly payload: Employee) {}
}

export class SetEmployeeByGid {
  static readonly type = '[MeritSupport] Set Employee By Gid';
  constructor(public readonly payload: string) {}
}

export class ChangeSortingParameter {
  static readonly type = '[MeritSupport] Change Sorting Parameter';
  constructor(public readonly payload: MeritSupportSortingParameter) {}
}

export class AddFilterParameter {
  static readonly type = '[MeritSupport] Change Filter Parameter';
  constructor(public readonly payload: ListViewTagFilterParameter) {}
}

export class ClearFilterParameterKey {
  static readonly type = '[MeritSupport] Clear Filter Parameter Key';
  constructor(public readonly category: string) {}
}

export class LoadSimulationMemberForEmployee {
  static readonly type = '[MeritSupport] Load Simulation Member For Employee';
  constructor(public readonly employeeId: string) {}
}

export class LoadSeasonSettings {
  static readonly type = '[MeritSupport] Load Season Settings';
  constructor(public readonly payload: { id: string; type: SeasonTypeEnum }) {}
}

export class LoadSeasonAllocationSettings {
  static readonly type = '[MeritSupport] Load Season Allocation Settings';
  constructor(public readonly payload: { seasonId: string }) {}
}

export class LoadSeasonCommunicationSetting {
  static readonly type = '[MeritSupport] Load Season Communication Setting';
  constructor(public readonly payload: { seasonId: string }) {}
}

export class UpdateSeasonCommunicationSetting {
  static readonly type = '[MeritSupport] Update Season Communication Setting';
  constructor(public readonly payload: { seasonId: string; isPromotionCommunicationActive: boolean }) {}
}

export class LoadSeasonPlans {
  static readonly type = '[MeritSupport] Load Season Plans';
  constructor(public readonly seasonId: string) {}
}

export class SetSeason {
  static readonly type = '[MeritSupport] Set Season';
  constructor(public readonly payload: MeritAllocationSeason) {}
}

export class ResetSeason {
  static readonly type = '[MeritSupport] Reset Season ';
}

export class SetHasOpenInitialLoad {
  static readonly type = '[MeritSupport] Set Has Open Initial Load ';
  constructor(public readonly payload: boolean) {}
}

export class ActivateMeritForEmployee {
  static readonly type = '[MeritSupport] Activate Merit For Employee';
  constructor(public readonly payload: { gid: string; employeeId: string; meritPlan: MeritPlan }) {}
}

export class ActivateEquityForEmployee {
  static readonly type = '[MeritSupport] Activate Equity For Employee';
  constructor(public readonly payload: { gid: string; employeeId: string; equityPlan: MeritEquityPlan; equityPlanDetails?: MeritEquityPlanDetails; positionClass?: number }) {}
}

export class DeactivateMeritForEmployee {
  static readonly type = '[MeritSupport] Deactivate Merit For Employee';
  constructor(public readonly payload: { employeeId: string; comment: string }) {}
}

export class DeactivateEquityForEmployee {
  static readonly type = '[MeritSupport] Deactivate Equity For Employee';
  constructor(public readonly payload: { employeeId: string; comment: string }) {}
}

export class ReviewPromotion {
  static readonly type = '[MeritSupport] Review Promotion';
  constructor(public readonly payload: { employeeId: string; state: MeritPromotionState }) {}
}

export class PromoteSupport {
  static readonly type = '[MeritSupport] Promote';
  constructor(public readonly payload: { employeeId: string; comment: string }) {}
}

export class ApproveNewHire {
  static readonly type = '[MeritSupport] Approve New Hire';
  constructor(
    public readonly payload: { employeeId: string; meritPlan: MeritPlan; equityPlan: MeritEquityPlan; equityPlanDetails: MeritEquityPlanDetails; positionClass: number }
  ) {}
}

export class RevertNewHireApproval {
  static readonly type = '[MeritSupport] Revert New Hire Approval';
  constructor(public readonly employeeId: string) {}
}

export class RejectNewHire {
  static readonly type = '[MeritSupport] Reject New Hire';
  constructor(public readonly employeeId: string) {}
}

export class RevertNewHireRejection {
  static readonly type = '[MeritSupport] Revert New Hire Rejection';
  constructor(public readonly employeeId: string) {}
}

export class RejectTermination {
  static readonly type = '[MeritSupport] Reject Termination';
  constructor(public readonly employeeId: string) {}
}

export class RevertTerminationRejection {
  static readonly type = '[MeritSupport] Revert Termination Rejection';
  constructor(public readonly employeeId: string) {}
}

export class ApproveTermination {
  static readonly type = '[MeritSupport] Approve Termination';
  constructor(public readonly employeeId: string) {}
}

export class MeritSupportChangeAllocationManager {
  static readonly type = '[MeritSupport] Change Allocation Manager';
  constructor(public readonly payload: { employeeId: string; newAllocationManagerId: string; newLineManagerId: string; newInCompanyManagerId: string }) {}
}

export class ResetSupportState {
  static readonly type = '[MeritSupport] Reset Support State';
}

export class LoadIncentiveAllocation {
  static readonly type = '[MeritSupport] Load Incentive Allocation';
  constructor(public readonly employeeId: string) {}
}

export class UpdateIndividualMultiplier {
  static readonly type = '[MeritSupport] Update Individual Multiplier';
  constructor(
    public readonly employeeId: string,
    public readonly multiplier: number
  ) {}
}

export class UpdateIndividualMultiplierState {
  static readonly type = '[MeritSupport] Update Individual Multiplier State';
  constructor(
    public readonly employeeId: string,
    public readonly multiplierState: IndividualMultiplierState
  ) {}
}

export class UpdateMeritIsDoneState {
  static readonly type = '[MeritSupport] Update Merit IsDone state';
  constructor(
    public readonly employeeId: string,
    public readonly isDone: boolean
  ) {}
}
