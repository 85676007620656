import { Injectable } from '@angular/core';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { ListViewTagFilterParameter, PaginationState } from '@coin/shared/util-models';
import { Store } from '@ngxs/store';
import { ImmerComponentStore } from 'ngrx-immer/component-store';
import { FilterSortState } from '@coin/customer/shared/filter-sort-bar-data-access';
import { GetSeasonsIncentiveSeasonIdGuidPartnerV1ReassignmentsRequestParams, IncentiveEmployee, IncentivePartnerService, IncentivePlan } from '../generated/index';
import { QueryParams } from '../models/query-params.model';
import { ReassignmentQuickFilter, ReassignmentState } from '../enums';
import { IncentiveSupportComponentState } from './incentive-support.component.state';

interface MonitoringStateModel {
  queryParams: QueryParams;
  monitoringList: {
    isSelected?: boolean;
    id?: string;
    newPlan?: IncentivePlan;
  }[];
  employeeData: { [key: string]: IncentiveEmployee };
  paginationState: PaginationState;
  filterParameter: ListViewTagFilterParameter[];
  isMultiselectActive: boolean;
}

const initialState = (): MonitoringStateModel => ({
  queryParams: {},
  monitoringList: [],
  employeeData: {},
  paginationState: {
    pageSize: 25,
    nextPage: 1,
    hasMore: true
  },
  filterParameter: [{ category: ReassignmentQuickFilter.State, value: ReassignmentState.Created }],
  isMultiselectActive: false
});

@Injectable()
export class MonitoringComponentState extends ImmerComponentStore<MonitoringStateModel> {
  public monitoringList$ = this.select(({ monitoringList }) => monitoringList);
  public selectedItems$ = this.select(({ monitoringList }) => monitoringList.filter(item => item.isSelected));
  public initialPageLoaded$ = this.select(({ paginationState }) => paginationState.nextPage >= initialState().paginationState.nextPage + 1);
  public filterParameter$ = this.select(({ filterParameter }) => filterParameter);
  public isMultiselectActive$ = this.select(({ isMultiselectActive }) => isMultiselectActive);
  public totalCount$ = this.select(({ paginationState }) => paginationState.total);
  public isOnlyCreatedFilterActive$ = this.select(
    ({ filterParameter }) =>
      filterParameter.length === 1 && filterParameter.find(parameter => parameter.category === ReassignmentQuickFilter.State)?.value === ReassignmentState.Created
  );
  public monitoringItemById$ = (id: string) => this.select(({ monitoringList }) => monitoringList.find(monitoringItem => monitoringItem.id === id));
  public employeeDataByReassignmentId$ = (id: string) => this.select(({ employeeData }) => employeeData?.[id]);

  constructor(
    private incentiveSupportState: IncentiveSupportComponentState,
    private store: Store
  ) {
    super(initialState());
  }

  public loadEmployeeData(id: string): void {
    const selectedSeason = this.incentiveSupportState.getSelectedSeason();
    if (selectedSeason) {
      // TODO: Load Employee Data
    }
  }

  public loadNextPage(): void {
    const selectedSeason = this.incentiveSupportState.getSelectedSeason();
    const { paginationState } = this.get();
    const { nextPage, pageSize, hasMore } = paginationState;

    if (hasMore) {
      const selectedSortOption = this.store.selectSnapshot(FilterSortState.selectedSortOption);

      const requestParams = {
        seasonId: selectedSeason.id,
        pagingPage: nextPage,
        pagingSize: pageSize,
        sortingOrderBy: selectedSortOption?.value,
        sortingProperty: selectedSortOption?.key
      };

      const requestParamsWithFilters = this.addSelectedFilters(this.addSelectedQuickFilters(requestParams));

      // TODO: Implement load next page when service is available
    }
  }

  private addSelectedQuickFilters(
    requestParams: GetSeasonsIncentiveSeasonIdGuidPartnerV1ReassignmentsRequestParams
  ): GetSeasonsIncentiveSeasonIdGuidPartnerV1ReassignmentsRequestParams {
    const { filterParameter } = this.get();
    const stateFilter = filterParameter.find(parameter => parameter.category === ReassignmentQuickFilter.State);
    const reasonFilters = filterParameter.filter(parameter => parameter.category === ReassignmentQuickFilter.Reason);
    return {
      ...requestParams,
      queryState: stateFilter?.value ?? ReassignmentState.Created,
      queryReason: reasonFilters.map(reasonFilter => reasonFilter.value)
    };
  }

  private addSelectedFilters(
    requestParams: GetSeasonsIncentiveSeasonIdGuidPartnerV1ReassignmentsRequestParams
  ): GetSeasonsIncentiveSeasonIdGuidPartnerV1ReassignmentsRequestParams {
    const selectedFilters = this.store.selectSnapshot(FilterSortState.selectedFilterOptions);
    const returnParams = { ...requestParams };
    for (const selectedFilter of selectedFilters) {
      const isStartsWithOrContainsFilter = /StartsWith|Contains/.test(selectedFilter.key);
      const apiFilterKeyPrefix = 'query';
      const apiFilterKey = `${apiFilterKeyPrefix}${selectedFilter.key.replace(/StartsWith|Contains/, '')}`;
      if (selectedFilter.values) {
        returnParams[apiFilterKey] = isStartsWithOrContainsFilter ? selectedFilter.values[0] : selectedFilter.values;
      }
    }
    return returnParams;
  }

  public addFilterParameter(parameter: ListViewTagFilterParameter): void {
    this.setState(state => {
      state.filterParameter.push(parameter);
    });
  }

  public removeFilterParameter(parameter: ListViewTagFilterParameter): void {
    this.setState(state => {
      state.filterParameter = state.filterParameter.filter(existingFilterParameter => existingFilterParameter.value !== parameter.value);
    });
  }

  public resetFilterParameters(): void {
    this.setState(state => {
      state.filterParameter = initialState().filterParameter;
    });
  }

  public toggleMultiselect(): void {
    this.setState(state => {
      state.isMultiselectActive = !state.isMultiselectActive;
    });
  }

  public unselectAll(): void {
    this.setState(state => {
      state.monitoringList = state.monitoringList.map(item => ({ ...item, isSelected: false }));
    });
  }

  public toggleSelection(index: number): void {
    this.setState(state => {
      state.monitoringList[index].isSelected = !state.monitoringList[index]?.isSelected;
    });
  }

  public resetPaginationState(): void {
    this.setState(state => {
      state.paginationState = initialState().paginationState;
      state.monitoringList = [];
    });
  }
}
