<div style="margin-top: 20px">
  @if (startanimation) {
    <div style="display: flex; width: 100%; height: 600px">
      <div style="flex: 2; display: flex; flex-direction: column; margin: 5px; margin-left: 0px; margin-right: 0px; position: relative" class="group-container">
        @if (contentEdit) {
          <div class="group-action-button-container">
            <coin-action-button class="primary" (click)="add(['left'])" [icon]="'add'">Add</coin-action-button>
          </div>
        }
        @for (item of item.collection.left; track item; let i = $index) {
          <div class="compilation-image" style="margin: 5px; margin-left: 0px; flex: 1">
            @if (contentEdit) {
              <coin-action-button (click)="editPicture($any(item))" class="secondary" svgIcon="edit" style="position: absolute; left: 5px; top: 5px" [noicon]="true">
                {{ 'cms.content-pages.change-image-button' | translate }}
              </coin-action-button>
            }
            <div
              class="sub-img"
              @shrinkExpandSlow
              [ngStyle]="{ background: 'url(' + item.img + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat', width: '100%', height: '100%' }"></div>
            @if (!contentEdit) {
              <h3 @shrinkExpandSlow>
                <span>{{ item.text }}</span>
              </h3>
            }
            @if (contentEdit) {
              <h3><span [contentEditable]="contentEdit" [textContent]="item.text" (input)="item.text = $event.target.textContent" style="cursor: text"></span></h3>
            }
            @if (contentEdit) {
              <div class="action-button-container">
                <coin-action-button class="secondary" (click)="remove(['left'], i)" [icon]="'close'">Remove</coin-action-button>
              </div>
            }
          </div>
        }
      </div>
      <div style="flex: 3; display: flex; flex-direction: column; margin-left: 5px">
        <div class="basic-container group-container" style="flex-direction: row; margin: 5px 0px; margin-right: 0px; margin-bottom: 0px; position: relative">
          @if (contentEdit) {
            <div class="group-action-button-container" style="right: 10px">
              <coin-action-button class="primary" (click)="add(['right', 'top'])" [icon]="'add'"></coin-action-button>
            </div>
          }
          @for (item of item.collection.right.top; track item; let i = $index) {
            <div class="compilation-image" style="margin: 5px">
              @if (contentEdit) {
                <coin-action-button (click)="editPicture($any(item))" class="secondary" svgIcon="edit" style="position: absolute; left: 5px; top: 5px" [noicon]="true">
                  {{ 'cms.content-pages.change-image-button' | translate }}
                </coin-action-button>
              }
              <div
                class="sub-img"
                @shrinkExpandSlow
                [ngStyle]="{ background: 'url(' + item.img + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat', width: '100%', height: '100%' }"></div>
              @if (!contentEdit) {
                <h3 @shrinkExpandSlow>
                  <span>{{ item.text }}</span>
                </h3>
              }
              @if (contentEdit) {
                <h3><span [contentEditable]="contentEdit" [textContent]="item.text" (input)="item.text = $event.target.textContent" style="cursor: text"></span></h3>
              }
              @if (contentEdit) {
                <div class="action-button-container">
                  <coin-action-button class="secondary" (click)="remove(['right', 'top'], i)" [icon]="'close'"></coin-action-button>
                </div>
              }
            </div>
          }
        </div>
        <div class="bottom-right">
          <div class="basic-container with-margin group-container" style="flex-direction: column; margin-left: 0px; margin-right: 5px">
            @if (contentEdit) {
              <div class="group-action-button-container" style="right: 0px">
                <coin-action-button class="primary" (click)="add(['right', 'bottom', 'left'])" [icon]="'add'"></coin-action-button>
              </div>
            }
            @for (item of item.collection.right.bottom.left; track item; let i = $index) {
              <div class="compilation-image" style="margin: 5px; flex: 1">
                @if (contentEdit) {
                  <coin-action-button (click)="editPicture($any(item))" class="secondary" svgIcon="edit" style="position: absolute; left: 5px; top: 5px" [noicon]="true">
                    {{ 'cms.content-pages.change-image-button' | translate }}</coin-action-button
                  >
                }
                <div
                  class="sub-img"
                  @shrinkExpandSlow
                  [ngStyle]="{ background: 'url(' + item.img + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat', width: '100%', height: '100%' }"></div>
                @if (!contentEdit) {
                  <h3 @shrinkExpandSlow>
                    <span>{{ item.text }}</span>
                  </h3>
                }
                @if (contentEdit) {
                  <h3>
                    <span [contentEditable]="contentEdit" [textContent]="item.text" (input)="item.text = $event.target.textContent" style="cursor: text"></span>
                  </h3>
                }
                @if (contentEdit) {
                  <div class="action-button-container">
                    <coin-action-button class="secondary" (click)="remove(['right', 'bottom', 'left'], i)" [icon]="'close'"></coin-action-button>
                  </div>
                }
              </div>
            }
          </div>
          <div class="basic-container with-margin group-container" style="flex-direction: column">
            @if (contentEdit) {
              <div class="group-action-button-container" style="right: 0px">
                <coin-action-button class="primary" (click)="add(['right', 'bottom', 'right'])" [icon]="'add'"></coin-action-button>
              </div>
            }
            @for (item of item.collection.right.bottom.right; track item; let i = $index) {
              <div class="compilation-image" style="margin: 5px; flex: 1">
                @if (contentEdit) {
                  <coin-action-button (click)="editPicture($any(item))" class="secondary" svgIcon="edit" style="position: absolute; left: 5px; top: 5px" [noicon]="true">
                    {{ 'cms.content-pages.change-image-button' | translate }}</coin-action-button
                  >
                }
                <div
                  class="sub-img"
                  @shrinkExpandSlow
                  [ngStyle]="{ background: 'url(' + item.img + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat', width: '100%', height: '100%' }"></div>
                @if (!contentEdit) {
                  <h3 @shrinkExpandSlow>
                    <span>{{ item.text }}</span>
                  </h3>
                }
                @if (contentEdit) {
                  <h3>
                    <span [contentEditable]="contentEdit" [textContent]="item.text" (input)="item.text = $event.target.textContent" style="cursor: text"></span>
                  </h3>
                }
                @if (contentEdit) {
                  <div class="action-button-container">
                    <coin-action-button class="secondary" (click)="remove(['right', 'bottom', 'right'], i)" [icon]="'close'"></coin-action-button>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  }
</div>
