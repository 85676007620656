<mat-accordion>
  @for (question of value?.questions | filter: 'isActive' : true; track question; let i = $index) {
    <coin-customer-survey-question
      [index]="i"
      [question]="question"
      [expandedPanelIndex]="expandedPanelIndex"
      [isLocked]="isLocked"
      [isEditable]="isEditable"
      (togglePanel)="onTogglePanel(i)"
      (expandNextPanel)="expandNextPanel(i)"
      (answerToggle)="onAnswerToggle($event)"></coin-customer-survey-question>
  }
</mat-accordion>
