@if (contentEdit) {
  <p style="margin-bottom: 20px">Just a line.</p>
}
<div class="line-style" [ngClass]="{ 'line-style--fullWidth': item?.fullWidth }"></div>

@if (contentEdit && item) {
  <coin-v2-checkbox class="spacing-elements" [(ngModel)]="item.fullWidth" [label]="'Full Width'" />
  @if (showBackgroundPicker) {
    <div class="spacing-elements">
      <coin-color-picker [(ngModel)]="item.backgroundColor"></coin-color-picker>
    </div>
    <div class="spacing-elements">
      <coin-color-picker [(ngModel)]="item.textColor" [isTextColorPicker]="true"></coin-color-picker>
    </div>
  }
}
