import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import { FilterOptions, FilterSetting, SortingSetting } from '@coin/shared/util-models';
import { TinyHelpers } from '@coin/shared/util-helpers';
import { FilterSortHelper } from '../helpers/filter-sort.helper';
import {
  RemoveFilterOption,
  RemoveFilterOptionByKey,
  ResetFilterOption,
  ResetSortAndFilterOption,
  ResetSortOption,
  SetFilterOptions,
  SetFilterOptionsFromText,
  SetFilterValues,
  SetReloadNeeded,
  SetSortOption
} from './filter-sort.actions';

const STATE_DEFAULTS: FilterSortStateModel = {
  selectedFilterOptions: [],
  selectedSortOption: null,
  isReloadNeeded: false
};
interface FilterSortStateModel {
  selectedFilterOptions: FilterSetting[];
  selectedSortOption: SortingSetting;
  isReloadNeeded: boolean;
}
@State<FilterSortStateModel>({
  name: 'FilterSortState',
  defaults: STATE_DEFAULTS
})
@Injectable()
export class FilterSortState {
  @Selector()
  static selectedFilterOptions(state: FilterSortStateModel): FilterSetting[] {
    return state.selectedFilterOptions;
  }

  @Selector()
  static selectedFilterKeys(state: FilterSortStateModel): string[] {
    return state.selectedFilterOptions.map(filter => filter.key);
  }

  @Selector()
  static isReloadNeeded(state: FilterSortStateModel): boolean {
    return state.isReloadNeeded;
  }

  @Selector()
  static queryText(state: FilterSortStateModel): string {
    return FilterSortState.getSortAndFilterText(state)
      .replace(/Query./g, '')
      .replace(/Sorting./g, '');
  }

  @Selector()
  static getFilterText(state: FilterSortStateModel): string {
    return FilterSortHelper.getFilterQuery(state.selectedFilterOptions);
  }

  @Selector()
  static getSortAndFilterText(state: FilterSortStateModel): string {
    let filterSortText = '';
    filterSortText += this.getFilterText(state);

    if (state.selectedSortOption?.key) {
      filterSortText += `&Sorting.Property=${state.selectedSortOption.key}&Sorting.OrderBy=${encodeURIComponent(state.selectedSortOption.value)}`;
    }
    return filterSortText;
  }

  @Selector()
  static hasFiltersSelected(state: FilterSortStateModel): boolean {
    return state.selectedFilterOptions?.length > 0;
  }

  @Selector()
  static selectedSortOption(state: FilterSortStateModel): SortingSetting {
    return state.selectedSortOption;
  }

  static matchesFilter(item: unknown): (s: FilterSetting[]) => boolean {
    return createSelector([FilterSortState.selectedFilterOptions], (filters: FilterSetting[]) =>
      filters.every(filterSetting => {
        if (!filterSetting.values?.length) return true;
        const key = TinyHelpers.uncapitalize(filterSetting.key);
        return filterSetting.values.some(value => value.toLowerCase() === item[key]?.toString()?.toLowerCase());
      })
    );
  }

  @Action(SetFilterValues)
  setFilterValues(ctx: StateContext<FilterSortStateModel>, { payload }: SetFilterValues): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.selectedFilterOptions[payload.index].values = payload.values;
      })
    );
  }

  @Action(SetReloadNeeded)
  setReloadNeeded(ctx: StateContext<FilterSortStateModel>, { payload }: SetReloadNeeded): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.isReloadNeeded = payload.isReloadNeeded;
      })
    );
  }

  @Action(RemoveFilterOptionByKey)
  removeFilterOptionByKey(ctx: StateContext<FilterSortStateModel>, { payload }: RemoveFilterOptionByKey): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.selectedFilterOptions = state.selectedFilterOptions.filter(filter => payload.filterOptionKey !== filter.key);
      })
    );
  }

  @Action(RemoveFilterOption)
  removeFilterOption(ctx: StateContext<FilterSortStateModel>, { payload }: RemoveFilterOption): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.selectedFilterOptions = state.selectedFilterOptions.filter(filter => payload.filterOption.key !== filter.key);
      })
    );
  }

  @Action(SetFilterOptions)
  setFilterOptions(ctx: StateContext<FilterSortStateModel>, { payload }: SetFilterOptions): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.selectedFilterOptions = payload.filterOptions.map(option => {
          const previousValues = state.selectedFilterOptions.find(selection => selection.key === option.key)?.values;

          return { key: option.key, values: previousValues || option.values || null };
        });
      })
    );
  }

  @Action(SetFilterOptionsFromText)
  setFilterOptionsFromText(ctx: StateContext<FilterSortStateModel>, { payload }: SetFilterOptionsFromText): void {
    const params = new HttpParams({ fromString: payload });

    const selectedFilterOptions: FilterSetting[] = params
      .keys()
      .filter(key => key)
      .map((paramKey: FilterOptions) => {
        const key = paramKey.replace(/Query./g, '') as FilterOptions;
        const values = params.getAll(paramKey);
        return { key, values };
      });
    ctx.patchState({ selectedFilterOptions });
  }

  @Action(SetSortOption)
  setSortOption(ctx: StateContext<FilterSortStateModel>, { payload }: SetSortOption): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.selectedSortOption = payload.sortOption;
      })
    );
  }

  @Action(ResetFilterOption)
  resetFilterOptions(ctx: StateContext<FilterSortStateModel>): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.selectedFilterOptions = [];
      })
    );
  }

  @Action(ResetSortOption)
  resetSortOptions(ctx: StateContext<FilterSortStateModel>): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.selectedSortOption = null;
      })
    );
  }

  @Action(ResetSortAndFilterOption)
  resetSortAndFilterOptions(ctx: StateContext<FilterSortStateModel>): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        state.selectedSortOption = null;
        state.selectedFilterOptions = [];
      })
    );
  }
}
