/**
 * IncentivePartnerApp
 * API for incentive HR support app
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomHttpParameterCodec } from '../encoder';

// @ts-ignore
import { AcceptReassignment } from '../model/accept-reassignment.model';
// @ts-ignore
import { AcceptReassignments } from '../model/accept-reassignments.model';
// @ts-ignore
import { CreateManualReassignment } from '../model/create-manual-reassignment.model';
// @ts-ignore
import { ExportTransactionStatus } from '../model/export-transaction-status.model';
// @ts-ignore
import { FieldValuesPage } from '../model/field-values-page.model';
// @ts-ignore
import { IncentiveEmployee } from '../model/incentive-employee.model';
// @ts-ignore
import { PlanPage } from '../model/plan-page.model';
// @ts-ignore
import { Reassignment } from '../model/reassignment.model';
// @ts-ignore
import { ReassignmentsPage } from '../model/reassignments-page.model';
// @ts-ignore
import { RejectReassignments } from '../model/reject-reassignments.model';
// @ts-ignore
import { TransactionStatusMassOperation } from '../model/transaction-status-mass-operation.model';
// @ts-ignore
import { UpdateReassignment } from '../model/update-reassignment.model';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

export interface GetSeasonsIncentiveSeasonIdGuidPartnerV1PlansRequestParams {
  seasonId: string;
  queryDisplayName?: string;
  queryTitle?: string;
  pagingPage?: number;
  pagingSize?: number;
  sortingOrderBy?: string;
  sortingProperty?: string;
}

export interface GetSeasonsIncentiveSeasonIdGuidPartnerV1ReassignmentsRequestParams {
  seasonId: string;
  queryTargetProperty?: string;
  queryGid?: string;
  queryFirstname?: string;
  queryLastname?: string;
  queryReason?: Array<string>;
  queryStartDate?: string;
  queryEndDate?: string;
  queryAction?: string;
  queryState?: string;
  queryAreNew?: Array<string>;
  queryAreCurrent?: Array<string>;
  queryCountryNew?: Array<string>;
  queryCountryCurrent?: Array<string>;
  queryOrgCodeNew?: Array<string>;
  queryOrgCodeCurrent?: Array<string>;
  queryPlanNameNew?: Array<string>;
  queryPlanNameCurrent?: Array<string>;
  queryIncentivePaymentTypeCurrent?: Array<string>;
  queryIncentivePaymentTypeNew?: Array<string>;
  queryOrgClusterCurrent?: Array<string>;
  queryOrgClusterNew?: Array<string>;
  queryManagementType?: Array<string>;
  queryNewManagementType?: Array<string>;
  queryTargetIncentiveAmountCurrent?: number;
  queryTargetIncentiveAmountNew?: number;
  pagingPage?: number;
  pagingSize?: number;
  sortingOrderBy?: string;
  sortingProperty?: string;
  /** OR Search for AssignmentId, Gid, Firstname, Lastname */
  querySearch?: string;
}

export interface GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRequestParams {
  seasonId: string;
  queryTargetProperty?: string;
  queryReason?: string;
  queryState?: string;
  queryAre?: string;
  queryCountry?: string;
  queryPlan?: string;
  queryIPT?: string;
  queryNewIPT?: string;
  queryOrgCluster?: string;
  queryManagementType?: string;
  queryNewManagementType?: string;
  pagingPage?: number;
  pagingSize?: number;
  queryNewPlan?: string;
  queryNewCountry?: string;
  queryNewAre?: string;
  queryNewOrgCluster?: string;
  queryOrgCodeNew?: string;
  queryOrgCodeCurrent?: string;
}

export interface GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdRequestParams {
  seasonId: string;
  id: string;
}

export interface GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdEmployeeRequestParams {
  seasonId: string;
  id: string;
}

export interface PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsAcceptRequestParams {
  seasonId: string;
  queryTargetProperty?: string;
  queryGid?: string;
  queryFirstname?: string;
  queryLastname?: string;
  queryReason?: Array<string>;
  queryStartDate?: string;
  queryEndDate?: string;
  queryAction?: string;
  queryState?: string;
  queryAreNew?: Array<string>;
  queryAreCurrent?: Array<string>;
  queryCountryNew?: Array<string>;
  queryCountryCurrent?: Array<string>;
  queryOrgCodeNew?: Array<string>;
  queryOrgCodeCurrent?: Array<string>;
  queryPlanNameNew?: Array<string>;
  queryPlanNameCurrent?: Array<string>;
  queryIncentivePaymentTypeCurrent?: Array<string>;
  queryIncentivePaymentTypeNew?: Array<string>;
  queryOrgClusterCurrent?: Array<string>;
  queryOrgClusterNew?: Array<string>;
  queryManagementType?: Array<string>;
  queryNewManagementType?: Array<string>;
  queryTargetIncentiveAmountCurrent?: number;
  queryTargetIncentiveAmountNew?: number;
  pagingPage?: number;
  pagingSize?: number;
  sortingOrderBy?: string;
  sortingProperty?: string;
  acceptReassignments?: AcceptReassignments;
}

export interface PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsExportRequestParams {
  seasonId: string;
  queryTargetProperty?: string;
  queryGid?: string;
  queryFirstname?: string;
  queryLastname?: string;
  queryReason?: Array<string>;
  queryStartDate?: string;
  queryEndDate?: string;
  queryAction?: string;
  queryState?: string;
  queryAreNew?: Array<string>;
  queryAreCurrent?: Array<string>;
  queryCountryNew?: Array<string>;
  queryCountryCurrent?: Array<string>;
  queryOrgCodeNew?: Array<string>;
  queryOrgCodeCurrent?: Array<string>;
  queryPlanNameNew?: Array<string>;
  queryPlanNameCurrent?: Array<string>;
  queryIncentivePaymentTypeCurrent?: Array<string>;
  queryIncentivePaymentTypeNew?: Array<string>;
  queryOrgClusterCurrent?: Array<string>;
  queryOrgClusterNew?: Array<string>;
  queryManagementType?: Array<string>;
  queryNewManagementType?: Array<string>;
  queryTargetIncentiveAmountCurrent?: number;
  queryTargetIncentiveAmountNew?: number;
  body?: object;
}

export interface PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdRequestParams {
  seasonId: string;
  id: string;
  updateReassignment?: UpdateReassignment;
}

export interface PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdAcceptRequestParams {
  seasonId: string;
  id: string;
  acceptReassignment?: AcceptReassignment;
}

export interface PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdRejectRequestParams {
  seasonId: string;
  id: string;
  body?: object;
}

export interface PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsManualRequestParams {
  seasonId: string;
  createManualReassignment?: CreateManualReassignment;
}

export interface PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRejectRequestParams {
  seasonId: string;
  queryTargetProperty?: string;
  queryGid?: string;
  queryFirstname?: string;
  queryLastname?: string;
  queryReason?: Array<string>;
  queryStartDate?: string;
  queryEndDate?: string;
  queryAction?: string;
  queryState?: string;
  queryAreNew?: Array<string>;
  queryAreCurrent?: Array<string>;
  queryCountryNew?: Array<string>;
  queryCountryCurrent?: Array<string>;
  queryOrgCodeNew?: Array<string>;
  queryOrgCodeCurrent?: Array<string>;
  queryPlanNameNew?: Array<string>;
  queryPlanNameCurrent?: Array<string>;
  queryIncentivePaymentTypeCurrent?: Array<string>;
  queryIncentivePaymentTypeNew?: Array<string>;
  queryOrgClusterCurrent?: Array<string>;
  queryOrgClusterNew?: Array<string>;
  queryManagementType?: Array<string>;
  queryNewManagementType?: Array<string>;
  queryTargetIncentiveAmountCurrent?: number;
  queryTargetIncentiveAmountNew?: number;
  pagingPage?: number;
  pagingSize?: number;
  sortingOrderBy?: string;
  sortingProperty?: string;
  rejectReassignments?: RejectReassignments;
}

@Injectable({
  providedIn: 'root'
})
export class ReassignmentService {
  protected basePath = 'http://localhost:3000';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * GET Incentive Plans
   * get paged plans
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSeasonsIncentiveSeasonIdGuidPartnerV1Plans(
    requestParameters: GetSeasonsIncentiveSeasonIdGuidPartnerV1PlansRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<PlanPage>;
  public getSeasonsIncentiveSeasonIdGuidPartnerV1Plans(
    requestParameters: GetSeasonsIncentiveSeasonIdGuidPartnerV1PlansRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<PlanPage>>;
  public getSeasonsIncentiveSeasonIdGuidPartnerV1Plans(
    requestParameters: GetSeasonsIncentiveSeasonIdGuidPartnerV1PlansRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<PlanPage>>;
  public getSeasonsIncentiveSeasonIdGuidPartnerV1Plans(
    requestParameters: GetSeasonsIncentiveSeasonIdGuidPartnerV1PlansRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    const { seasonId } = requestParameters;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling getSeasonsIncentiveSeasonIdGuidPartnerV1Plans.');
    }
    const { queryDisplayName } = requestParameters;
    const { queryTitle } = requestParameters;
    const { pagingPage } = requestParameters;
    const { pagingSize } = requestParameters;
    const { sortingOrderBy } = requestParameters;
    const { sortingProperty } = requestParameters;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (queryDisplayName !== undefined && queryDisplayName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryDisplayName, 'query.DisplayName');
    }
    if (queryTitle !== undefined && queryTitle !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryTitle, 'query.Title');
    }
    if (pagingPage !== undefined && pagingPage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingPage, 'paging.Page');
    }
    if (pagingSize !== undefined && pagingSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingSize, 'paging.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'sorting.Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/partner/v1/plans`;
    return this.httpClient.request<PlanPage>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * GET Reassignments
   * get paged reassignments
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSeasonsIncentiveSeasonIdGuidPartnerV1Reassignments(
    requestParameters: GetSeasonsIncentiveSeasonIdGuidPartnerV1ReassignmentsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ReassignmentsPage>;
  public getSeasonsIncentiveSeasonIdGuidPartnerV1Reassignments(
    requestParameters: GetSeasonsIncentiveSeasonIdGuidPartnerV1ReassignmentsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ReassignmentsPage>>;
  public getSeasonsIncentiveSeasonIdGuidPartnerV1Reassignments(
    requestParameters: GetSeasonsIncentiveSeasonIdGuidPartnerV1ReassignmentsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ReassignmentsPage>>;
  public getSeasonsIncentiveSeasonIdGuidPartnerV1Reassignments(
    requestParameters: GetSeasonsIncentiveSeasonIdGuidPartnerV1ReassignmentsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    const { seasonId } = requestParameters;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling getSeasonsIncentiveSeasonIdGuidPartnerV1Reassignments.');
    }
    const { queryTargetProperty } = requestParameters;
    const { queryGid } = requestParameters;
    const { queryFirstname } = requestParameters;
    const { queryLastname } = requestParameters;
    const { queryReason } = requestParameters;
    const { queryStartDate } = requestParameters;
    const { queryEndDate } = requestParameters;
    const { queryAction } = requestParameters;
    const { queryState } = requestParameters;
    const { queryAreNew } = requestParameters;
    const { queryAreCurrent } = requestParameters;
    const { queryCountryNew } = requestParameters;
    const { queryCountryCurrent } = requestParameters;
    const { queryOrgCodeNew } = requestParameters;
    const { queryOrgCodeCurrent } = requestParameters;
    const { queryPlanNameNew } = requestParameters;
    const { queryPlanNameCurrent } = requestParameters;
    const { queryIncentivePaymentTypeCurrent } = requestParameters;
    const { queryIncentivePaymentTypeNew } = requestParameters;
    const { queryOrgClusterCurrent } = requestParameters;
    const { queryOrgClusterNew } = requestParameters;
    const { queryManagementType } = requestParameters;
    const { queryNewManagementType } = requestParameters;
    const { queryTargetIncentiveAmountCurrent } = requestParameters;
    const { queryTargetIncentiveAmountNew } = requestParameters;
    const { pagingPage } = requestParameters;
    const { pagingSize } = requestParameters;
    const { sortingOrderBy } = requestParameters;
    const { sortingProperty } = requestParameters;
    const { querySearch } = requestParameters;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (queryTargetProperty !== undefined && queryTargetProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryTargetProperty, 'query.TargetProperty');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'query.Gid');
    }
    if (queryFirstname !== undefined && queryFirstname !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstname, 'query.Firstname');
    }
    if (queryLastname !== undefined && queryLastname !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastname, 'query.Lastname');
    }
    if (queryReason) {
      queryReason.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.Reason');
      });
    }
    if (queryStartDate !== undefined && queryStartDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryStartDate, 'query.StartDate');
    }
    if (queryEndDate !== undefined && queryEndDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryEndDate, 'query.EndDate');
    }
    if (queryAction !== undefined && queryAction !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAction, 'query.Action');
    }
    if (queryState !== undefined && queryState !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryState, 'query.State');
    }
    if (queryAreNew) {
      queryAreNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.AreNew');
      });
    }
    if (queryAreCurrent) {
      queryAreCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.AreCurrent');
      });
    }
    if (queryCountryNew) {
      queryCountryNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.CountryNew');
      });
    }
    if (queryCountryCurrent) {
      queryCountryCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.CountryCurrent');
      });
    }
    if (queryOrgCodeNew) {
      queryOrgCodeNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.OrgCodeNew');
      });
    }
    if (queryOrgCodeCurrent) {
      queryOrgCodeCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.OrgCodeCurrent');
      });
    }
    if (queryPlanNameNew) {
      queryPlanNameNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.PlanNameNew');
      });
    }
    if (queryPlanNameCurrent) {
      queryPlanNameCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.PlanNameCurrent');
      });
    }
    if (queryIncentivePaymentTypeCurrent) {
      queryIncentivePaymentTypeCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.IncentivePaymentTypeCurrent');
      });
    }
    if (queryIncentivePaymentTypeNew) {
      queryIncentivePaymentTypeNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.IncentivePaymentTypeNew');
      });
    }
    if (queryOrgClusterCurrent) {
      queryOrgClusterCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.OrgClusterCurrent');
      });
    }
    if (queryOrgClusterNew) {
      queryOrgClusterNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.OrgClusterNew');
      });
    }
    if (queryManagementType) {
      queryManagementType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.ManagementType');
      });
    }
    if (queryNewManagementType) {
      queryNewManagementType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.NewManagementType');
      });
    }
    if (queryTargetIncentiveAmountCurrent !== undefined && queryTargetIncentiveAmountCurrent !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryTargetIncentiveAmountCurrent, 'query.TargetIncentiveAmountCurrent');
    }
    if (queryTargetIncentiveAmountNew !== undefined && queryTargetIncentiveAmountNew !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryTargetIncentiveAmountNew, 'query.TargetIncentiveAmountNew');
    }
    if (pagingPage !== undefined && pagingPage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingPage, 'paging.Page');
    }
    if (pagingSize !== undefined && pagingSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingSize, 'paging.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'sorting.Property');
    }
    if (querySearch !== undefined && querySearch !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>querySearch, 'query.Search');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/partner/v1/reassignments`;
    return this.httpClient.request<ReassignmentsPage>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * GET Reassignments Field Values
   * get paged reassignments
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSeasonsIncentiveSeasonIdPartnerV1Reassignments(
    requestParameters: GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<FieldValuesPage>;
  public getSeasonsIncentiveSeasonIdPartnerV1Reassignments(
    requestParameters: GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<FieldValuesPage>>;
  public getSeasonsIncentiveSeasonIdPartnerV1Reassignments(
    requestParameters: GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<FieldValuesPage>>;
  public getSeasonsIncentiveSeasonIdPartnerV1Reassignments(
    requestParameters: GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    const { seasonId } = requestParameters;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling getSeasonsIncentiveSeasonIdPartnerV1Reassignments.');
    }
    const { queryTargetProperty } = requestParameters;
    const { queryReason } = requestParameters;
    const { queryState } = requestParameters;
    const { queryAre } = requestParameters;
    const { queryCountry } = requestParameters;
    const { queryPlan } = requestParameters;
    const { queryIPT } = requestParameters;
    const { queryNewIPT } = requestParameters;
    const { queryOrgCluster } = requestParameters;
    const { queryManagementType } = requestParameters;
    const { queryNewManagementType } = requestParameters;
    const { pagingPage } = requestParameters;
    const { pagingSize } = requestParameters;
    const { queryNewPlan } = requestParameters;
    const { queryNewCountry } = requestParameters;
    const { queryNewAre } = requestParameters;
    const { queryNewOrgCluster } = requestParameters;
    const { queryOrgCodeNew } = requestParameters;
    const { queryOrgCodeCurrent } = requestParameters;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (queryTargetProperty !== undefined && queryTargetProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryTargetProperty, 'query.TargetProperty');
    }
    if (queryReason !== undefined && queryReason !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryReason, 'query.Reason');
    }
    if (queryState !== undefined && queryState !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryState, 'query.State');
    }
    if (queryAre !== undefined && queryAre !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAre, 'query.Are');
    }
    if (queryCountry !== undefined && queryCountry !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryCountry, 'query.Country');
    }
    if (queryPlan !== undefined && queryPlan !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryPlan, 'query.Plan');
    }
    if (queryIPT !== undefined && queryIPT !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryIPT, 'query.IPT');
    }
    if (queryNewIPT !== undefined && queryNewIPT !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryNewIPT, 'query.NewIPT');
    }
    if (queryOrgCluster !== undefined && queryOrgCluster !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCluster, 'query.OrgCluster');
    }
    if (queryManagementType !== undefined && queryManagementType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryManagementType, 'query.ManagementType');
    }
    if (queryNewManagementType !== undefined && queryNewManagementType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryNewManagementType, 'query.NewManagementType');
    }
    if (pagingPage !== undefined && pagingPage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingPage, 'paging.Page');
    }
    if (pagingSize !== undefined && pagingSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingSize, 'paging.Size');
    }
    if (queryNewPlan !== undefined && queryNewPlan !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryNewPlan, 'query.NewPlan');
    }
    if (queryNewCountry !== undefined && queryNewCountry !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryNewCountry, 'query.NewCountry');
    }
    if (queryNewAre !== undefined && queryNewAre !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryNewAre, 'query.NewAre');
    }
    if (queryNewOrgCluster !== undefined && queryNewOrgCluster !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryNewOrgCluster, 'query.NewOrgCluster');
    }
    if (queryOrgCodeNew !== undefined && queryOrgCodeNew !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeNew, 'query.OrgCodeNew');
    }
    if (queryOrgCodeCurrent !== undefined && queryOrgCodeCurrent !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeCurrent, 'query.OrgCodeCurrent');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/partner/v1/reassignments/field-values`;
    return this.httpClient.request<FieldValuesPage>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * GET Reassignments
   * get reassignment by id
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSeasonsIncentiveSeasonIdPartnerV1ReassignmentsId(
    requestParameters: GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<Reassignment>;
  public getSeasonsIncentiveSeasonIdPartnerV1ReassignmentsId(
    requestParameters: GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<Reassignment>>;
  public getSeasonsIncentiveSeasonIdPartnerV1ReassignmentsId(
    requestParameters: GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<Reassignment>>;
  public getSeasonsIncentiveSeasonIdPartnerV1ReassignmentsId(
    requestParameters: GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    const { seasonId } = requestParameters;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling getSeasonsIncentiveSeasonIdPartnerV1ReassignmentsId.');
    }
    const { id } = requestParameters;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getSeasonsIncentiveSeasonIdPartnerV1ReassignmentsId.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/partner/v1/reassignments/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<Reassignment>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * GET Reassignments
   * get employee by reassignment id
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdEmployee(
    requestParameters: GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdEmployeeRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<IncentiveEmployee>;
  public getSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdEmployee(
    requestParameters: GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdEmployeeRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<IncentiveEmployee>>;
  public getSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdEmployee(
    requestParameters: GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdEmployeeRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<IncentiveEmployee>>;
  public getSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdEmployee(
    requestParameters: GetSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdEmployeeRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    const { seasonId } = requestParameters;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling getSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdEmployee.');
    }
    const { id } = requestParameters;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdEmployee.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/partner/v1/reassignments/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/employee`;
    return this.httpClient.request<IncentiveEmployee>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * PUT - Mass Accept Reassignments
   * Mass accept reassignment either via query or by providing a list of reassignment ids
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsAccept(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsAcceptRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<TransactionStatusMassOperation>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsAccept(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsAcceptRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<TransactionStatusMassOperation>>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsAccept(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsAcceptRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<TransactionStatusMassOperation>>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsAccept(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsAcceptRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    const { seasonId } = requestParameters;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsAccept.');
    }
    const { queryTargetProperty } = requestParameters;
    const { queryGid } = requestParameters;
    const { queryFirstname } = requestParameters;
    const { queryLastname } = requestParameters;
    const { queryReason } = requestParameters;
    const { queryStartDate } = requestParameters;
    const { queryEndDate } = requestParameters;
    const { queryAction } = requestParameters;
    const { queryState } = requestParameters;
    const { queryAreNew } = requestParameters;
    const { queryAreCurrent } = requestParameters;
    const { queryCountryNew } = requestParameters;
    const { queryCountryCurrent } = requestParameters;
    const { queryOrgCodeNew } = requestParameters;
    const { queryOrgCodeCurrent } = requestParameters;
    const { queryPlanNameNew } = requestParameters;
    const { queryPlanNameCurrent } = requestParameters;
    const { queryIncentivePaymentTypeCurrent } = requestParameters;
    const { queryIncentivePaymentTypeNew } = requestParameters;
    const { queryOrgClusterCurrent } = requestParameters;
    const { queryOrgClusterNew } = requestParameters;
    const { queryManagementType } = requestParameters;
    const { queryNewManagementType } = requestParameters;
    const { queryTargetIncentiveAmountCurrent } = requestParameters;
    const { queryTargetIncentiveAmountNew } = requestParameters;
    const { pagingPage } = requestParameters;
    const { pagingSize } = requestParameters;
    const { sortingOrderBy } = requestParameters;
    const { sortingProperty } = requestParameters;
    const { acceptReassignments } = requestParameters;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (queryTargetProperty !== undefined && queryTargetProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryTargetProperty, 'query.TargetProperty');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'query.Gid');
    }
    if (queryFirstname !== undefined && queryFirstname !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstname, 'query.Firstname');
    }
    if (queryLastname !== undefined && queryLastname !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastname, 'query.Lastname');
    }
    if (queryReason) {
      queryReason.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.Reason');
      });
    }
    if (queryStartDate !== undefined && queryStartDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryStartDate, 'query.StartDate');
    }
    if (queryEndDate !== undefined && queryEndDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryEndDate, 'query.EndDate');
    }
    if (queryAction !== undefined && queryAction !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAction, 'query.Action');
    }
    if (queryState !== undefined && queryState !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryState, 'query.State');
    }
    if (queryAreNew) {
      queryAreNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.AreNew');
      });
    }
    if (queryAreCurrent) {
      queryAreCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.AreCurrent');
      });
    }
    if (queryCountryNew) {
      queryCountryNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.CountryNew');
      });
    }
    if (queryCountryCurrent) {
      queryCountryCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.CountryCurrent');
      });
    }
    if (queryOrgCodeNew) {
      queryOrgCodeNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.OrgCodeNew');
      });
    }
    if (queryOrgCodeCurrent) {
      queryOrgCodeCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.OrgCodeCurrent');
      });
    }
    if (queryPlanNameNew) {
      queryPlanNameNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.PlanNameNew');
      });
    }
    if (queryPlanNameCurrent) {
      queryPlanNameCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.PlanNameCurrent');
      });
    }
    if (queryIncentivePaymentTypeCurrent) {
      queryIncentivePaymentTypeCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.IncentivePaymentTypeCurrent');
      });
    }
    if (queryIncentivePaymentTypeNew) {
      queryIncentivePaymentTypeNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.IncentivePaymentTypeNew');
      });
    }
    if (queryOrgClusterCurrent) {
      queryOrgClusterCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.OrgClusterCurrent');
      });
    }
    if (queryOrgClusterNew) {
      queryOrgClusterNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.OrgClusterNew');
      });
    }
    if (queryManagementType) {
      queryManagementType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.ManagementType');
      });
    }
    if (queryNewManagementType) {
      queryNewManagementType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.NewManagementType');
      });
    }
    if (queryTargetIncentiveAmountCurrent !== undefined && queryTargetIncentiveAmountCurrent !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryTargetIncentiveAmountCurrent, 'query.TargetIncentiveAmountCurrent');
    }
    if (queryTargetIncentiveAmountNew !== undefined && queryTargetIncentiveAmountNew !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryTargetIncentiveAmountNew, 'query.TargetIncentiveAmountNew');
    }
    if (pagingPage !== undefined && pagingPage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingPage, 'paging.Page');
    }
    if (pagingSize !== undefined && pagingSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingSize, 'paging.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'sorting.Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/partner/v1/reassignments/accept`;
    return this.httpClient.request<TransactionStatusMassOperation>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: acceptReassignments,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * PUT - Export Reassignments
   * Export reassignment either via query
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsExport(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsExportRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<ExportTransactionStatus>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsExport(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsExportRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<ExportTransactionStatus>>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsExport(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsExportRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<ExportTransactionStatus>>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsExport(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsExportRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    const { seasonId } = requestParameters;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsExport.');
    }
    const { queryTargetProperty } = requestParameters;
    const { queryGid } = requestParameters;
    const { queryFirstname } = requestParameters;
    const { queryLastname } = requestParameters;
    const { queryReason } = requestParameters;
    const { queryStartDate } = requestParameters;
    const { queryEndDate } = requestParameters;
    const { queryAction } = requestParameters;
    const { queryState } = requestParameters;
    const { queryAreNew } = requestParameters;
    const { queryAreCurrent } = requestParameters;
    const { queryCountryNew } = requestParameters;
    const { queryCountryCurrent } = requestParameters;
    const { queryOrgCodeNew } = requestParameters;
    const { queryOrgCodeCurrent } = requestParameters;
    const { queryPlanNameNew } = requestParameters;
    const { queryPlanNameCurrent } = requestParameters;
    const { queryIncentivePaymentTypeCurrent } = requestParameters;
    const { queryIncentivePaymentTypeNew } = requestParameters;
    const { queryOrgClusterCurrent } = requestParameters;
    const { queryOrgClusterNew } = requestParameters;
    const { queryManagementType } = requestParameters;
    const { queryNewManagementType } = requestParameters;
    const { queryTargetIncentiveAmountCurrent } = requestParameters;
    const { queryTargetIncentiveAmountNew } = requestParameters;
    const { body } = requestParameters;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (queryTargetProperty !== undefined && queryTargetProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryTargetProperty, 'query.TargetProperty');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'query.Gid');
    }
    if (queryFirstname !== undefined && queryFirstname !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstname, 'query.Firstname');
    }
    if (queryLastname !== undefined && queryLastname !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastname, 'query.Lastname');
    }
    if (queryReason) {
      queryReason.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.Reason');
      });
    }
    if (queryStartDate !== undefined && queryStartDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryStartDate, 'query.StartDate');
    }
    if (queryEndDate !== undefined && queryEndDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryEndDate, 'query.EndDate');
    }
    if (queryAction !== undefined && queryAction !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAction, 'query.Action');
    }
    if (queryState !== undefined && queryState !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryState, 'query.State');
    }
    if (queryAreNew) {
      queryAreNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.AreNew');
      });
    }
    if (queryAreCurrent) {
      queryAreCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.AreCurrent');
      });
    }
    if (queryCountryNew) {
      queryCountryNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.CountryNew');
      });
    }
    if (queryCountryCurrent) {
      queryCountryCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.CountryCurrent');
      });
    }
    if (queryOrgCodeNew) {
      queryOrgCodeNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.OrgCodeNew');
      });
    }
    if (queryOrgCodeCurrent) {
      queryOrgCodeCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.OrgCodeCurrent');
      });
    }
    if (queryPlanNameNew) {
      queryPlanNameNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.PlanNameNew');
      });
    }
    if (queryPlanNameCurrent) {
      queryPlanNameCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.PlanNameCurrent');
      });
    }
    if (queryIncentivePaymentTypeCurrent) {
      queryIncentivePaymentTypeCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.IncentivePaymentTypeCurrent');
      });
    }
    if (queryIncentivePaymentTypeNew) {
      queryIncentivePaymentTypeNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.IncentivePaymentTypeNew');
      });
    }
    if (queryOrgClusterCurrent) {
      queryOrgClusterCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.OrgClusterCurrent');
      });
    }
    if (queryOrgClusterNew) {
      queryOrgClusterNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.OrgClusterNew');
      });
    }
    if (queryManagementType) {
      queryManagementType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.ManagementType');
      });
    }
    if (queryNewManagementType) {
      queryNewManagementType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.NewManagementType');
      });
    }
    if (queryTargetIncentiveAmountCurrent !== undefined && queryTargetIncentiveAmountCurrent !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryTargetIncentiveAmountCurrent, 'query.TargetIncentiveAmountCurrent');
    }
    if (queryTargetIncentiveAmountNew !== undefined && queryTargetIncentiveAmountNew !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryTargetIncentiveAmountNew, 'query.TargetIncentiveAmountNew');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/partner/v1/reassignments/export`;
    return this.httpClient.request<ExportTransactionStatus>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: body,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * PUT - Update Reassignment
   * update reassignment details
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsId(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<Reassignment>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsId(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<Reassignment>>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsId(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<Reassignment>>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsId(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    const { seasonId } = requestParameters;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsId.');
    }
    const { id } = requestParameters;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsId.');
    }
    const { updateReassignment } = requestParameters;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/partner/v1/reassignments/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<Reassignment>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: updateReassignment,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * PUT - Accept Reassignment
   * Accept an open reassignment
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdAccept(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdAcceptRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<Reassignment>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdAccept(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdAcceptRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<Reassignment>>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdAccept(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdAcceptRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<Reassignment>>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdAccept(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdAcceptRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    const { seasonId } = requestParameters;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdAccept.');
    }
    const { id } = requestParameters;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdAccept.');
    }
    const { acceptReassignment } = requestParameters;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/partner/v1/reassignments/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/accept`;
    return this.httpClient.request<Reassignment>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: acceptReassignment,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * PUT - Reject Reassignment
   * Reject an open reassignment
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdReject(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdRejectRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<Reassignment>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdReject(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdRejectRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<Reassignment>>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdReject(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdRejectRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<Reassignment>>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdReject(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdRejectRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    const { seasonId } = requestParameters;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdReject.');
    }
    const { id } = requestParameters;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsIdReject.');
    }
    const { body } = requestParameters;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/partner/v1/reassignments/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/reject`;
    return this.httpClient.request<Reassignment>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: body,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * POST - Create manual Reassignment
   * Accept an open reassignment
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsManual(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsManualRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<Reassignment>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsManual(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsManualRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<Reassignment>>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsManual(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsManualRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<Reassignment>>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsManual(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsManualRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    const { seasonId } = requestParameters;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsManual.');
    }
    const { createManualReassignment } = requestParameters;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/partner/v1/reassignments/manuals`;
    return this.httpClient.request<Reassignment>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: createManualReassignment,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * PUT - Mass Reject Reassignments
   * Mass reject reassignment either via query or by providing a list of reassignment ids
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsReject(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRejectRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<TransactionStatusMassOperation>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsReject(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRejectRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpResponse<TransactionStatusMassOperation>>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsReject(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRejectRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<HttpEvent<TransactionStatusMassOperation>>;
  public putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsReject(
    requestParameters: PutSeasonsIncentiveSeasonIdPartnerV1ReassignmentsRejectRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
  ): Observable<any> {
    const { seasonId } = requestParameters;
    if (seasonId === null || seasonId === undefined) {
      throw new Error('Required parameter seasonId was null or undefined when calling putSeasonsIncentiveSeasonIdPartnerV1ReassignmentsReject.');
    }
    const { queryTargetProperty } = requestParameters;
    const { queryGid } = requestParameters;
    const { queryFirstname } = requestParameters;
    const { queryLastname } = requestParameters;
    const { queryReason } = requestParameters;
    const { queryStartDate } = requestParameters;
    const { queryEndDate } = requestParameters;
    const { queryAction } = requestParameters;
    const { queryState } = requestParameters;
    const { queryAreNew } = requestParameters;
    const { queryAreCurrent } = requestParameters;
    const { queryCountryNew } = requestParameters;
    const { queryCountryCurrent } = requestParameters;
    const { queryOrgCodeNew } = requestParameters;
    const { queryOrgCodeCurrent } = requestParameters;
    const { queryPlanNameNew } = requestParameters;
    const { queryPlanNameCurrent } = requestParameters;
    const { queryIncentivePaymentTypeCurrent } = requestParameters;
    const { queryIncentivePaymentTypeNew } = requestParameters;
    const { queryOrgClusterCurrent } = requestParameters;
    const { queryOrgClusterNew } = requestParameters;
    const { queryManagementType } = requestParameters;
    const { queryNewManagementType } = requestParameters;
    const { queryTargetIncentiveAmountCurrent } = requestParameters;
    const { queryTargetIncentiveAmountNew } = requestParameters;
    const { pagingPage } = requestParameters;
    const { pagingSize } = requestParameters;
    const { sortingOrderBy } = requestParameters;
    const { sortingProperty } = requestParameters;
    const { rejectReassignments } = requestParameters;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (queryTargetProperty !== undefined && queryTargetProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryTargetProperty, 'query.TargetProperty');
    }
    if (queryGid !== undefined && queryGid !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryGid, 'query.Gid');
    }
    if (queryFirstname !== undefined && queryFirstname !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryFirstname, 'query.Firstname');
    }
    if (queryLastname !== undefined && queryLastname !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryLastname, 'query.Lastname');
    }
    if (queryReason) {
      queryReason.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.Reason');
      });
    }
    if (queryStartDate !== undefined && queryStartDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryStartDate, 'query.StartDate');
    }
    if (queryEndDate !== undefined && queryEndDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryEndDate, 'query.EndDate');
    }
    if (queryAction !== undefined && queryAction !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryAction, 'query.Action');
    }
    if (queryState !== undefined && queryState !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryState, 'query.State');
    }
    if (queryAreNew) {
      queryAreNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.AreNew');
      });
    }
    if (queryAreCurrent) {
      queryAreCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.AreCurrent');
      });
    }
    if (queryCountryNew) {
      queryCountryNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.CountryNew');
      });
    }
    if (queryCountryCurrent) {
      queryCountryCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.CountryCurrent');
      });
    }
    if (queryOrgCodeNew) {
      queryOrgCodeNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.OrgCodeNew');
      });
    }
    if (queryOrgCodeCurrent) {
      queryOrgCodeCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.OrgCodeCurrent');
      });
    }
    if (queryPlanNameNew) {
      queryPlanNameNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.PlanNameNew');
      });
    }
    if (queryPlanNameCurrent) {
      queryPlanNameCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.PlanNameCurrent');
      });
    }
    if (queryIncentivePaymentTypeCurrent) {
      queryIncentivePaymentTypeCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.IncentivePaymentTypeCurrent');
      });
    }
    if (queryIncentivePaymentTypeNew) {
      queryIncentivePaymentTypeNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.IncentivePaymentTypeNew');
      });
    }
    if (queryOrgClusterCurrent) {
      queryOrgClusterCurrent.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.OrgClusterCurrent');
      });
    }
    if (queryOrgClusterNew) {
      queryOrgClusterNew.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.OrgClusterNew');
      });
    }
    if (queryManagementType) {
      queryManagementType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.ManagementType');
      });
    }
    if (queryNewManagementType) {
      queryNewManagementType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'query.NewManagementType');
      });
    }
    if (queryTargetIncentiveAmountCurrent !== undefined && queryTargetIncentiveAmountCurrent !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryTargetIncentiveAmountCurrent, 'query.TargetIncentiveAmountCurrent');
    }
    if (queryTargetIncentiveAmountNew !== undefined && queryTargetIncentiveAmountNew !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryTargetIncentiveAmountNew, 'query.TargetIncentiveAmountNew');
    }
    if (pagingPage !== undefined && pagingPage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingPage, 'paging.Page');
    }
    if (pagingSize !== undefined && pagingSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingSize, 'paging.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'sorting.Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/seasons/incentive/${this.configuration.encodeParam({ name: 'seasonId', value: seasonId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}/partner/v1/reassignments/reject`;
    return this.httpClient.request<TransactionStatusMassOperation>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: rejectReassignments,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
