import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { AppEnvironment, CustomTranslationLoader, TranslationDebugParser } from '@coin/shared/data-access-translation-loader';
import { TranslateModule } from '@ngx-translate/core';
import { SimplebarAngularModule } from 'simplebar-angular';
import { SharedModule } from '../shared/shared.module';
import { EquityLegalDisclaimerComponent } from './dialogs/equity-legal-disclaimer/equity-legal-disclaimer.component';

@NgModule({
  declarations: [EquityLegalDisclaimerComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    TranslateModule.forChild(CustomTranslationLoader.createConfig(AppEnvironment.Customer, TranslationDebugParser)),
    MatSlideToggleModule,
    FormsModule,
    SimplebarAngularModule,
    LegacyComponentsModule
  ]
})
export class EquityModule {}
