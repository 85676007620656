import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Fn } from '@coin/shared/util-models';

@Component({
  selector: 'coin-a-email-padding-slider',
  templateUrl: './email-padding-slider.component.html',
  styleUrls: ['./email-padding-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailPaddingSliderComponent),
      multi: true
    }
  ]
})
export class EmailPaddingSliderComponent implements ControlValueAccessor {
  @Input() label: string;

  private _value: number | null = null;

  public set value(val: number | null) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  public get value(): number | null {
    return this._value;
  }

  public onChange: Fn = () => {};
  public onTouch: Fn = () => {};

  public writeValue(value: number): void {
    this.value = value;
  }

  public registerOnChange(fn: Fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Fn): void {
    this.onTouch = fn;
  }
}
