<coin-v2-dialog-frame [header]="'cms.emails.select-index-item' | translate">
  <div class="body-container">
    <div class="selection-list">
      @for (item of selectionItems; track item) {
        <div
          class="selection-item"
          [class.selected]="selectedItem?.id === item.id"
          [class.active]="item.id | pure: isSelected : data.selectedItemReferenceIds"
          (click)="selectedItem = item">
          <div class="view-only" [innerHTML]="item.contentHtml"></div>
        </div>
      }
    </div>
  </div>
  <coin-v2-dialog-footer color="white">
    <coin-action-button class="secondary" (click)="close()">{{ 'general.btnClose' | translate }}</coin-action-button>
    <coin-action-button class="primary" (click)="proceed()" [disabled]="!selectedItem">{{ 'general.proceed' | translate }}</coin-action-button>
  </coin-v2-dialog-footer>
</coin-v2-dialog-frame>
