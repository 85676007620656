<button class="no-style burger-wrapper" (click)="onSidebarToggle()">
  <div class="menu-burger" id="menu-bar-close-open">
    @if (isSidebarVisible()) {
      <mat-icon @animateEnterLeave>close</mat-icon>
    }
    @if (!isSidebarVisible()) {
      <mat-icon @animateEnterLeave>menu</mat-icon>
    }
  </div>
</button>
<div class="components" role="menubar" [@showMenu]="isSidebarVisible() ? 'active' : 'inactive'">
  <ng-content></ng-content>
</div>
<app-collapsed-bar (toggleItem)="onSidebarToggle($event)" [titles]="titles" role="menubar" [@showMenu]="isSidebarVisible() ? 'inactive' : 'active'"></app-collapsed-bar>
