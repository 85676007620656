<div class="container">
  @if (pageContent && !contentHidden) {
    <div class="content">
      <div class="header">
        @if (parent.settings.overviewPage) {
          <div class="cp-menu" (click)="openOverview()" [ngClass]="{ 'cp-menu--active': currentRoute === '/' + this.parent.settings.routing }">
            <div class="material-icons-outlined">other_houses</div>
            <p style="color: white">{{ 'content-pages.overview' | translate }}</p>
          </div>
        }
        @if (parent.pages.length > 0 && parent.settings.showPagesInDropdown) {
          <div #cpmenu class="cp-menu cp-menu--programs" (click)="toggleProgramsMenu()" [ngClass]="{ 'cp-menu--active cp-menu--programs-active': programsMenuActive }">
            <div class="material-icons-outlined">view_carousel</div>
            <p style="color: white">{{ 'content-pages.programs' | translate }}</p>
            @if (programsMenuActive) {
              <div class="programs-menu">
                @for (menu of parent.pages; track menu) {
                  <div
                    (click)="menuItemClicked($event, menu)"
                    [ngClass]="{ 'programs-menu-active': getMenuActive(menu) }"
                    (mouseenter)="$any(menu).hover = true"
                    (mouseleave)="$any(menu).hover = false"
                    class="programs-menu-item"
                    @animateMenu>
                    <div class="material-icons-outlined">{{ menu.settings.icon }}</div>
                    <p>{{ menu.settings.title }}</p>
                    <div style="flex: 1"></div>
                    @if (menu.pages) {
                      <div class="material-icons">chevron_right</div>
                    }
                    @if (menu.pages && $any(menu).hover) {
                      <div class="programs-submenu" @animateMenu>
                        @for (submenu of menu.pages; track submenu) {
                          <div [ngClass]="{ 'programs-menu-active': getMenuActive(menu, submenu) }" (click)="menuItemClicked($event, submenu, menu)" class="programs-menu-item">
                            <div class="material-icons-outlined">{{ submenu.settings.icon }}</div>
                            <p>{{ submenu.settings.title }}</p>
                          </div>
                        }
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </div>
        }
        @if (parent.settings.documentsPage && isAuthenticated) {
          <div class="cp-menu" (click)="openDocuments()" [ngClass]="{ 'cp-menu--active': currentRoute.endsWith('/documents') }">
            <div class="material-icons-outlined">folder</div>
            <p style="color: white">{{ 'content-pages.documents' | translate }}</p>
          </div>
        }
      </div>
      <div class="main-part">
        @if (!pageContent[usedLang]?.hideChapterOverview && (responsive.isMobile$ | async) === false) {
          <app-equity-cms-chapter-overview [item]="pageContent[usedLang]?.content" [collapsed]="collapsedHeader$ | async"></app-equity-cms-chapter-overview>
        }
        <div class="simplebar">
          <ngx-simplebar class="primary-scrollbar-vertical equity cp-scrollthing" id="equity-simplebar">
            <div class="dynamic-content">
              <div class="content-pages-main-part news" style="padding-bottom: 100px">
                <app-all-cms-news-items
                  [isResponsive]="responsive.isMobile$ | async"
                  [hideChapters]="true"
                  [item]="pageContent[usedLang]"
                  [settings]="pageSettings"
                  (slideChange)="slideChange($event)"
                  [germanyRowType]="germanyRowType"
                  [germanyRowSlideToggle]="germanyRowSlideToggle"
                  [authService]="authService"
                  [isEquityPage]="true"
                  style="padding-right: 20px"></app-all-cms-news-items>
              </div>
            </div>
          </ngx-simplebar>
        </div>
      </div>
    </div>
  }
</div>
