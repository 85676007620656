<div class="container">
  <div class="close-button" (click)="closeFirstVisit()">
    <mat-icon>close</mat-icon>
  </div>
  <div class="content">
    <div class="header" cdkDrag cdkDragRootElement=".container" [cdkDragFreeDragPosition]="offset" cdkDragHandle>
      <h3 #testITEM class="fv-header-text">{{ 'guided-tour.top-headline' | translate }}</h3>
      <coin-simple-lang-switch></coin-simple-lang-switch>
    </div>
    @if (specialImageData) {
      <div class="special-image" [ngStyle]="{ 'background-image': 'url(' + specialImageData + ')' }"></div>
    }
    <mat-tab-group class="invisible-tabs" [selectedIndex]="selectedTab" (selectedIndexChange)="tabChange($event)">
      <mat-tab>
        <ng-template matTabContent>
          <app-intro class="max-height-area" [maxHeight]="tourMaxHeight"></app-intro>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template matTabContent>
          <app-fv-left [image]="imageLink" [text]="text" [maxHeight]="tourMaxHeight" [needsColumn]="needsColumn"> </app-fv-left>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template matTabContent>
          <app-fv-right [image]="imageLink" [text]="text" [maxHeight]="tourMaxHeight" [needsColumn]="needsColumn"> </app-fv-right>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template matTabContent>
          <app-fv-left [image]="imageLink" [text]="text" [maxHeight]="tourMaxHeight" [needsColumn]="needsColumn"> </app-fv-left>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template matTabContent>
          <app-fv-right [image]="imageLink" [text]="text" [maxHeight]="tourMaxHeight" [needsColumn]="needsColumn"> </app-fv-right>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template matTabContent>
          <app-fv-left [image]="imageLink" [text]="text" [maxHeight]="tourMaxHeight" [needsColumn]="needsColumn"> </app-fv-left>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template matTabContent>
          <app-fv-right [image]="imageLink" [text]="text" [maxHeight]="tourMaxHeight" [needsColumn]="needsColumn"> </app-fv-right>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <div class="fv-buttons">
      @if (selectedTab > 0) {
        <coin-action-button class="secondary fv-btn fv-btn--left" (click)="back()" arrowPos="left">Back</coin-action-button>
      }
      @if (selectedTab < 6) {
        <coin-action-button class="primary fv-btn fv-btn--right" (click)="next()"> {{ selectedTab > 0 ? 'Next' : 'Start Guided Tour' }}</coin-action-button>
      }
      @if (selectedTab === 6) {
        <coin-action-button class="primary fv-btn fv-btn--right" (click)="completeTour()"> {{ 'Tour completed' }}</coin-action-button>
      }
    </div>
    <div class="fv-fake-item">
      @if (showFakeItem && fakeItem) {
        <app-topic-item @shrinkExpand class="topic-item-container fake-child" [item]="fakeItem"></app-topic-item>
      }
    </div>
  </div>
</div>
@if (nso) {
  <div class="new-special-overlay" [ngStyle]="{ 'border-top-width': nso.bt, 'border-bottom-width': nso.bb, 'border-left-width': nso.bl, 'border-right-width': nso.br }"></div>
}
