import { Pipe, PipeTransform } from '@angular/core';
import { EntityRecord } from '@coin/shared/util-models';

@Pipe({
  name: 'normalizedEntityRecord',
  standalone: true
})
export class NormalizedEntityRecordPipe implements PipeTransform {
  transform<T extends object | EntityRecord<object>>(value: T): T {
    return 'origin' in value ? { ...value.origin, ...value } : value;
  }
}
